import styled from "styled-components";

const SurfaceHeadline = styled.h2(props => ({
    textTransform: "uppercase",
    fontWeight: "normal",
    fontSize: props.theme.typography.h6.fontSize,
    lineHeight: 1.2,
    marginBottom: props.theme.spacing(2),
    color: props.theme.palette.text.primary,
    [props.theme.breakpoints.down("xs")]: {
        fontSize: props.theme.typography.subtitle1.fontSize
    }
}));

export default SurfaceHeadline;