import { useTheme } from "@material-ui/core/styles";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export default function useMuiBreakpoint(upOrDown: "up" | "down", breakpoint: Breakpoint) {

    const theme = useTheme();

    return useMediaQuery(
        upOrDown === "up" ?
            theme.breakpoints.up(breakpoint) :
            theme.breakpoints.down(breakpoint)
    );
}