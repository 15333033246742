import React from "react";
import DescriptionIcon from "@material-ui/icons/Description";
import ImageIcon from "@material-ui/icons/Image";
import AudiotrackIcon from "@material-ui/icons/Audiotrack";
import VideocamIcon from "@material-ui/icons/Videocam";
import PersonIcon from "@material-ui/icons/Person";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import { FileCategory } from "../models/FileCategory";

interface IFileCategoryIconProps {
    category: FileCategory;
    children?: never;
}

const FileCategoryIcon: React.FC<IFileCategoryIconProps> = props => {

    if (props.category === FileCategory.document) {
        return <DescriptionIcon />;
    }

    if (props.category === FileCategory.image) {
        return <ImageIcon />;
    }

    if (props.category === FileCategory.audio) {
        return <AudiotrackIcon />;
    }

    if (props.category === FileCategory.video) {
        return <VideocamIcon />;
    }

    if (props.category === FileCategory.contact) {
        return <PersonIcon />;
    }

    return <InsertDriveFileIcon />;
};

export default FileCategoryIcon;