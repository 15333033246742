import styled from "styled-components";
import { lightGrey, textWhite } from "../../theme";

const NavigationListItem = styled.a<{ active: boolean, doublePitch?: boolean, display?: string }>(props => ({
    lineHeight: props.doublePitch ? 2 : 1.6,
    fontSize: props.theme.spacing(2),
    display: props.display || "inline-flex",
    fontWeight: props.active ? "bold" : "normal",
    textDecoration: "none",
    "&::before": {
        content: "\"\\25FC\"",
        marginRight: "0.5em",
        color: props.active ? textWhite : lightGrey
    },
    "@media (min-width: 725px)": {
        maxWidth: "30vw"
    }
}));

export default NavigationListItem;