import React from "react";
import styled from "styled-components";
import InputBase, { InputBaseProps } from "@material-ui/core/InputBase";
import { fade, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    inputRoot: {
        color: "inherit",
    },
    inputInput: {
        padding: theme.spacing(1, 0, 1, 1),
        paddingRight: `calc(1em + ${theme.spacing(4)}px)`
    },
    inputInputNoIcon: {
        padding: theme.spacing(1, 1, 1, 1)
    }
}));

const AppBarFieldContainer = styled.div(props => ({
    position: "relative",
    borderRadius: props.theme.shape.borderRadius,
    backgroundColor: fade(props.theme.palette.common.white, 0.15),
    "&:hover": {
        backgroundColor: fade(props.theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    [props.theme.breakpoints.up("sm")]: {
        marginLeft: props.theme.spacing(1),
        width: "auto"
    }
}));

const IconContainer = styled.div({
    padding: "0.8em",
    height: "100%",
    position: "absolute",
    right: 0,
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
});

interface IAppBarTextFieldProps extends InputBaseProps {
    endIcon?: React.ReactNode;
    elementRef?: React.RefObject<any>;
}

const AppBarTextField: React.FC<IAppBarTextFieldProps> = React.memo(props => {

    const { inputRoot, inputInput, inputInputNoIcon } = useStyles();

    const { endIcon, className, elementRef, ...inputBaseProps } = props;

    return (
        <AppBarFieldContainer className={className} ref={elementRef}>
            {endIcon && (
                <IconContainer>
                    {endIcon}
                </IconContainer>
            )}
            <InputBase
                {...inputBaseProps}
                classes={{
                    root: inputRoot,
                    input: endIcon ? inputInput : inputInputNoIcon
                }}
            />
        </AppBarFieldContainer>
    );
});

export default AppBarTextField;