import Container, { Token } from "typedi";
import createApiRequest, { extractResponse, getApiUrl } from "./utils/createApiRequest";
import { HttpMethod } from "@shared/requests/RequestDefinition";
import type { AuthStatus } from "@shared/requests/AuthStatus";
import type { AuthLogin } from "@shared/requests/AuthLogin";
import type { AuthLogout } from "@shared/requests/AuthLogout";
import type { AccountGetUsername } from "@shared/requests/AccountGetUsername";
import type { AccountCustomerDataList } from "@shared/requests/AccountCustomerDataList";
import type { AccountBackOfficeDataList } from "@shared/requests/AccountBackOfficeDataList";
import type { AccountUpdateDisplayName } from "@shared/requests/AccountUpdateDisplayName";
import type { AccountUpdateEmail } from "@shared/requests/AccountUpdateEmail";
import type { AccountUpdateDomain } from "@shared/requests/AccountUpdateDomain";
import type { AccountCreateCustomer } from "@shared/requests/AccountCreateCustomer";
import type { AccountUpdateLocked } from "@shared/requests/AccountUpdateLocked";
import type { AccountUpdateRoles } from "@shared/requests/AccountUpdateRoles";
import type { AccountDelete } from "@shared/requests/AccountDelete";
import type { AccountCreateBackOffice } from "@shared/requests/AccountCreateBackOffice";
import type { AccountCompanySearch } from "@shared/requests/AccountCompanySearch";
import type { AccountOfficeSearch } from "@shared/requests/AccountOfficeSearch";
import type { AuthConfirm } from "@shared/requests/AuthConfirm";
import type { AccountSendPasswordMail } from "@shared/requests/AccountSendPasswordMail";
import type { AuthForgotPassword } from "@shared/requests/AuthForgotPassword";
import type { AuthChangePassword } from "@shared/requests/AuthChangePassword";
import type { ToolsSetupNetworkDriveFile } from "@shared/requests/ToolsSetupNetworkDriveFile";
import type { ToolsIconsList } from "@shared/requests/ToolsIconsList";
import type { Browse } from "@shared/requests/Browse";
import type { BrowseDirInfo } from "@shared/requests/BrowseDirInfo";
import type { BrowseContents } from "@shared/requests/BrowseContents";
import type { Notifications } from "@shared/requests/Notifications";
import type { NotificationsRead } from "@shared/requests/NotificationsRead";
import type { NotificationsAll } from "@shared/requests/NotificationsAll";
import type { NotificationsCreate } from "@shared/requests/NotificationsCreate";
import type { NotificationsDelete } from "@shared/requests/NotificationsDelete";
import type { AccountUpdateAttributes } from "@shared/requests/AccountUpdateAttributes";
import type { AttributeCreate } from "@shared/requests/AttributeCreate";
import type { AttributeDelete } from "@shared/requests/AttributeDelete";
import type { AttributeGetAll } from "@shared/requests/AttributeGetAll";
import type { AttributeUpdate } from "@shared/requests/AttributeUpdate";
import type { DirectorySearch } from "@shared/requests/DirectorySearch";
import type { DirectoryPatch } from "@shared/requests/DirectoryPatch";
import type { AccountCompanyList } from "@shared/requests/AccountOfficeList";
import type { AccountOfficeList } from "@shared/requests/AccountCompanyList";
import { directoryPostUrl, IDirectoryPostResponse } from "@shared/requests/DirectoryPost";
import type { NewsDataList } from "@shared/requests/NewsDataList";
import type { NewsCreate } from "@shared/requests/NewsCreate";
import type { NewsPatch } from "@shared/requests/NewsPatch";
import type { NewsDelete } from "@shared/requests/NewsDelete";
import type { NewsGetForPath } from "@shared/requests/NewsGetForPath";
import type { ContactRead } from "@shared/requests/ContactRead";
import type { ContactPut } from "@shared/requests/ContactPut";
import type { AnnouncementActive } from "@shared/requests/AnnouncementActive";
import type { DirectoryDelete } from "@shared/requests/DirectoryDelete";
import type { AnnouncementPut } from "@shared/requests/AnnouncementPut";
import type { AnnouncementDelete } from "@shared/requests/AnnouncementDelete";
import type { AnnouncementDataList } from "@shared/requests/AnnouncementDataList";
import type { Search } from "@shared/requests/Search";
import type { FormSubmit } from "@shared/requests/FormSubmit";

export const authStatusToken = new Token<AuthStatus>("AuthStatus");
Container.set(
    authStatusToken,
    createApiRequest<AuthStatus>(HttpMethod.get, "/auth/status")
);

export const authLoginToken = new Token<AuthLogin>("AuthLogin");
Container.set(
    authLoginToken,
    createApiRequest<AuthLogin>(HttpMethod.post, "/auth/login")
);

export const authLogoutToken = new Token<AuthLogout>("AuthLogout");
Container.set(
    authLogoutToken,
    createApiRequest<AuthLogout>(HttpMethod.get, "/auth/logout")
);

export const authConfirmToken = new Token<AuthConfirm>("AuthConfirm");
Container.set(
    authConfirmToken,
    createApiRequest<AuthConfirm>(HttpMethod.post, "/auth/confirm/:username/")
);

export const authForgotPasswordToken = new Token<AuthForgotPassword>("AuthForgotPassword");
Container.set(
    authForgotPasswordToken,
    createApiRequest<AuthForgotPassword>(HttpMethod.post, "/auth/forgot-password")
);

export const authChangePasswordToken = new Token<AuthChangePassword>("AuthChangePassword");
Container.set(
    authChangePasswordToken,
    createApiRequest<AuthChangePassword>(HttpMethod.post, "/auth/change-password")
);

export const accountGetUsernameToken = new Token<AccountGetUsername>("AccountGetUsername");
Container.set(
    accountGetUsernameToken,
    createApiRequest<AccountGetUsername>(HttpMethod.get, "/account/get/:username/")
);

export const accountCustomerDataListToken = new Token<AccountCustomerDataList>("AccountCustomerDataList");
Container.set(
    accountCustomerDataListToken,
    createApiRequest<AccountCustomerDataList>(HttpMethod.get, "/account/customer/list")
);

export const accountBackOfficeDataListToken = new Token<AccountBackOfficeDataList>("AccountBackOfficeDataList");
Container.set(
    accountBackOfficeDataListToken,
    createApiRequest<AccountBackOfficeDataList>(HttpMethod.get, "/account/backoffice/list")
);

export const accountUpdateDisplayNameToken = new Token<AccountUpdateDisplayName>("AccountUpdateDisplayName");
Container.set(
    accountUpdateDisplayNameToken,
    createApiRequest<AccountUpdateDisplayName>(HttpMethod.post, "/account/:username/display-name")
);

export const accountUpdateEmailToken = new Token<AccountUpdateEmail>("AccountUpdateEmail");
Container.set(
    accountUpdateEmailToken,
    createApiRequest<AccountUpdateEmail>(HttpMethod.post, "/account/:username/email")
);

export const accountUpdateDomainToken = new Token<AccountUpdateDomain>("AccountUpdateEmail");
Container.set(
    accountUpdateDomainToken,
    createApiRequest<AccountUpdateDomain>(HttpMethod.post, "/account/:username/domain")
);

export const accountUpdateAttributesToken = new Token<AccountUpdateAttributes>("AccountUpdateAttributes");
Container.set(
    accountUpdateAttributesToken,
    createApiRequest<AccountUpdateAttributes>(HttpMethod.post, "/account/:username/attributes")
);

export const accountCreateCustomerToken = new Token<AccountCreateCustomer>("AccountCreateCustomer");
Container.set(
    accountCreateCustomerToken,
    createApiRequest<AccountCreateCustomer>(HttpMethod.post, "/account/create-customer")
);

export const accountCreateBackOfficeToken = new Token<AccountCreateCustomer>("AccountCreateCustomer");
Container.set(
    accountCreateBackOfficeToken,
    createApiRequest<AccountCreateBackOffice>(HttpMethod.post, "/account/create-backoffice")
);

export const accountUpdateLockedToken = new Token<AccountUpdateLocked>("AccountUpdateLocked");
Container.set(
    accountUpdateLockedToken,
    createApiRequest<AccountUpdateLocked>(HttpMethod.post, "/account/:username/locked")
);

export const accountUpdateRolesToken = new Token<AccountUpdateRoles>("AccountUpdateRoles");
Container.set(
    accountUpdateRolesToken,
    createApiRequest<AccountUpdateRoles>(HttpMethod.post, "/account/:username/roles")
);

export const accountDeleteToken = new Token<AccountDelete>("AccountDelete");
Container.set(
    accountDeleteToken,
    createApiRequest<AccountDelete>(HttpMethod.post, "/account/:username/delete")
);

export const accountSendPasswordMailToken = new Token<AccountSendPasswordMail>("AccountSendPasswordMail");
Container.set(
    accountSendPasswordMailToken,
    createApiRequest<AccountSendPasswordMail>(HttpMethod.get, "/account/:username/sendSetPasswordMail")
);

export const browseToken = new Token<Browse>("Browse");
Container.set(
    browseToken,
    createApiRequest<Browse>(HttpMethod.get, "/browse/")
);

export const browseDirInfoToken = new Token<BrowseDirInfo>("BrowseDirInfo");
Container.set(
    browseDirInfoToken,
    createApiRequest<BrowseDirInfo>(HttpMethod.get, "/browse/dir-info")
);

export const browseContentsToken = new Token<BrowseContents>("BrowseContents");
Container.set(
    browseContentsToken,
    createApiRequest<BrowseContents>(HttpMethod.get, "/browse/contents")
);

export const toolsSetupNetworkDriveFileToken = new Token<Browse>("ToolsSetupNetworkDriveFile");
Container.set(
    toolsSetupNetworkDriveFileToken,
    createApiRequest<ToolsSetupNetworkDriveFile>(HttpMethod.post, "/tools/setup-network-drive")
);

export const toolsIconsListToken = new Token<ToolsIconsList>("ToolsIconsList");
Container.set(
    toolsIconsListToken,
    createApiRequest<ToolsIconsList>(HttpMethod.get, "/tools/icons-list")
);

export const notificationsToken = new Token<Notifications>("Notifications");
Container.set(
    notificationsToken,
    createApiRequest<Notifications>(HttpMethod.get, "/notifications")
);

export const notificationsReadToken = new Token<NotificationsRead>("NotificationsRead");
Container.set(
    notificationsReadToken,
    createApiRequest<NotificationsRead>(HttpMethod.get, "/notifications/read")
);

export const notificationsAllToken = new Token<NotificationsAll>("NotificationsAll");
Container.set(
    notificationsAllToken,
    createApiRequest<NotificationsAll>(HttpMethod.get, "/notifications/all")
);

export const notificationsCreateToken = new Token<NotificationsCreate>("NotificationsCreate");
Container.set(
    notificationsCreateToken,
    createApiRequest<NotificationsCreate>(HttpMethod.post, "/notifications")
);

export const notificationsDeleteToken = new Token<NotificationsDelete>("NotificationsDelete");
Container.set(
    notificationsDeleteToken,
    createApiRequest<NotificationsDelete>(HttpMethod.delete, "/notifications")
);

export const attributeCreateToken = new Token<AttributeCreate>("AttributeCreate");
Container.set(
    attributeCreateToken,
    createApiRequest<AttributeCreate>(HttpMethod.post, "/attribute")
);

export const attributeDeleteToken = new Token<AttributeDelete>("AttributeDelete");
Container.set(
    attributeDeleteToken,
    createApiRequest<AttributeDelete>(HttpMethod.delete, "/attribute/:name")
);

export const attributeGetAllToken = new Token<AttributeGetAll>("AttributeGetAll");
Container.set(
    attributeGetAllToken,
    createApiRequest<AttributeGetAll>(HttpMethod.get, "/attribute")
);

export const attributeUpdateToken = new Token<AttributeUpdate>("AttributeUpdate");
Container.set(
    attributeUpdateToken,
    createApiRequest<AttributeUpdate>(HttpMethod.patch, "/attribute/:name")
);

export const accountCompanySearchToken = new Token<AccountCompanySearch>("AccountCompanySearch");
Container.set(
    accountCompanySearchToken,
    createApiRequest<AccountCompanySearch>(HttpMethod.get, "/account/company/search")
);

export const accountCompanyListToken = new Token<AccountCompanyList>("AccountCompanyList");
Container.set(
    accountCompanyListToken,
    createApiRequest<AccountCompanyList>(HttpMethod.get, "/account/company")
);

export const accountOfficeSearchToken = new Token<AccountOfficeSearch>("AccountOfficeSearch");
Container.set(
    accountOfficeSearchToken,
    createApiRequest<AccountOfficeSearch>(HttpMethod.get, "/account/office/search")
);

export const accountOfficeListToken = new Token<AccountOfficeList>("AccountOfficeList");
Container.set(
    accountOfficeListToken,
    createApiRequest<AccountOfficeList>(HttpMethod.get, "/account/office")
);

export const directorySearchToken = new Token<DirectorySearch>("DirectorySearch");
Container.set(
    directorySearchToken,
    createApiRequest<DirectorySearch>(HttpMethod.get, "/directory/search")
);

export const directoryPatchToken = new Token<DirectoryPatch>("DirectoryPatch");
Container.set(
    directoryPatchToken,
    createApiRequest<DirectoryPatch>(HttpMethod.patch, "/directory")
);

export const newsDataListToken = new Token<NewsDataList>("NewsDataList");
Container.set(
    newsDataListToken,
    createApiRequest<NewsDataList>(HttpMethod.get, "/news/list")
);

export const newsCreateToken = new Token<NewsCreate>("NewsCreate");
Container.set(
    newsCreateToken,
    createApiRequest<NewsCreate>(HttpMethod.post, "/news")
);

export const newsPatchToken = new Token<NewsPatch>("NewsPatch");
Container.set(
    newsPatchToken,
    createApiRequest<NewsPatch>(HttpMethod.patch, "/news/:id")
);

export const newsDeleteToken = new Token<NewsDelete>("NewsDelete");
Container.set(
    newsDeleteToken,
    createApiRequest<NewsDelete>(HttpMethod.delete, "/news/:id")
);

export const newsGetForPathToken = new Token<NewsGetForPath>("NewsGetForPath");
Container.set(
    newsGetForPathToken,
    createApiRequest<NewsGetForPath>(HttpMethod.get, "/news")
);

async function directoryPost(formData: FormData, path: string, notify: boolean) {

    const query: Record<string, string> = notify ? { path, notify: "true" } : { path };

    const response = await fetch(getApiUrl(directoryPostUrl, null, query), {
        method: "POST",
        body: formData,
        credentials: "include"
    });

    return extractResponse<IDirectoryPostResponse>(response);
}

export const directoryPostToken = new Token<typeof directoryPost>("DirectoryPost");
Container.set(
    directoryPostToken,
    directoryPost
);

export const directoryDeleteToken = new Token<DirectoryDelete>("DirectoryDelete");
Container.set(
    directoryDeleteToken,
    createApiRequest<DirectoryDelete>(HttpMethod.delete, "/directory")
);

export const contactReadToken = new Token<ContactRead>("ContactRead");
Container.set(
    contactReadToken,
    createApiRequest<ContactRead>(HttpMethod.get, "/contact/read")
);

export const contactPutToken = new Token<ContactPut>("ContactPut");
Container.set(
    contactPutToken,
    createApiRequest<ContactPut>(HttpMethod.put, "/contact")
);

export const announcementActiveToken = new Token<AnnouncementActive>("AnnouncementActive");
Container.set(
    announcementActiveToken,
    createApiRequest<AnnouncementActive>(HttpMethod.get, "/announcement/active")
);

export const announcementPutToken = new Token<AnnouncementPut>("AnnouncementPut");
Container.set(
    announcementPutToken,
    createApiRequest<AnnouncementPut>(HttpMethod.put, "/announcement/:id?")
);

export const announcementDeleteToken = new Token<AnnouncementDelete>("AnnouncementDelete");
Container.set(
    announcementDeleteToken,
    createApiRequest<AnnouncementDelete>(HttpMethod.delete, "/announcement/:id")
);

export const announcementDataListToken = new Token<AnnouncementDataList>("AnnouncementDataList");
Container.set(
    announcementDataListToken,
    createApiRequest<AnnouncementDataList>(HttpMethod.get, "/announcement/list")
);

export const searchToken = new Token<Search>("Search");
Container.set(
    searchToken,
    createApiRequest<Search>(HttpMethod.get, "/search")
);

export const formSubmitToken = new Token<FormSubmit>("FormSubmit");
Container.set(
    formSubmitToken,
    createApiRequest<FormSubmit>(HttpMethod.post, "/form/submit")
);