import styled from "styled-components";

interface IFlowGroupProps {
    hSpace?: number;
    vSpace?: number;
}

const FlowGroup = styled.div<IFlowGroupProps>(props => ({
    "& > *": {
        marginRight: props.theme.spacing(props.hSpace),
        marginBottom: props.theme.spacing(props.vSpace)
    },
    "& > :last-child": {
        marginRight: 0
    }
}));

FlowGroup.defaultProps = {
    hSpace: 4,
    vSpace: 2
};

export default FlowGroup;