import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";
interface IConfirmDialogProps {
    title: string;
    abortLabel?: string;
    confirmLabel?: string;
    isOpen: boolean;
    onAbort: () => void;
    onConfirm: () => void;
}

const ConfirmDialog: React.FC<IConfirmDialogProps> = props => {

    const titleId = "confirm-dialog-" + btoa(props.title);

    return (
        <Dialog open={props.isOpen} onClose={props.onAbort} aria-labelledby={titleId}>
            <DialogTitle id={titleId}>{props.title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {props.children}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onAbort}>
                    {props.abortLabel}
                </Button>
                <Button color="primary" onClick={props.onConfirm}>
                    {props.confirmLabel}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

ConfirmDialog.defaultProps = {
    abortLabel: "Abbrechen",
    confirmLabel: "OK"
};

export default ConfirmDialog;