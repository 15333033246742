import { makeAutoObservable, reaction } from "mobx";
import { Service } from "typedi";

export enum NotificationType {
    normal = "normal",
    error = "error",
    warn = "warn",
    info = "info",
    success = "success"
}

export interface INotificationAction {
    label: string;
    handle: () => void;
}

interface INotification {
    content: React.ReactElement | string;
    action: INotificationAction | null,
    closable: boolean;
    type: NotificationType;
    /**
     * Milliseconds
     */
    lifetime: number;
    id: string;
}

@Service()
export default class NotificationService {

    private maxConcurrent: number;

    private timeouts = new Map<string, number>();

    private stack = new Array<INotification>();

    get currentlyDisplayed() {

        return this.stack.slice(0, this.maxConcurrent);
    }

    constructor(maxConcurrent = 6) {

        this.maxConcurrent = Math.max(maxConcurrent, 1);

        makeAutoObservable(this, undefined);

        reaction(() => this.currentlyDisplayed, (items, oldItems) => {

            const oldIds = oldItems.map(i => i.id);

            items.forEach(i => {

                if (!oldIds.includes(i.id)) {
                    this.addTimeoutForId(i.id, i.lifetime);
                }
            });
        });
    }

    show(
        content: React.ReactElement | string,
        type = NotificationType.normal,
        closable = true,
        action: INotificationAction | null = null,
        lifetime = 3000
    ) {

        const notification: INotification = {
            content,
            type,
            closable,
            action,
            lifetime,
            id: Date.now().toString()
        };

        this.stack.push(notification);
    }

    hide(id: string) {

        this.stack = this.stack.filter(n => n.id !== id);

        window.clearTimeout(this.timeouts.get(id));
    }

    hideAll() {

        this.stack.forEach(n => this.hide(n.id));
    }

    private addTimeoutForId(id: string, lifetime: number) {

        const timeoutId = window.setTimeout(() => {
            this.hide(id);
        }, lifetime);

        this.timeouts.set(id, timeoutId);
    }
}