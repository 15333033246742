import React from "react";
import styled, { css, keyframes } from "styled-components";

const badgeSize = 26;
const borderWidth = 2;

const blink = keyframes({
    "0%": {
        opacity: 1
    },
    "100%": {
        opacity: 0.6
    }
});

const CustomBadgeBase = styled.div(props => ({
    color: props.theme.palette.primary.contrastText,
    fontWeight: 700,
    height: badgeSize,
    minWidth: badgeSize,
    padding: "0 6px",
    fontSize: badgeSize * 0.6,
    lineHeight: `${badgeSize - (borderWidth * 2)}px`,
    borderRadius: badgeSize / 2,
    border: `${borderWidth}px solid ${props.theme.palette.primary.contrastText}`,
    textAlign: "center"
}), css`animation: ${blink} 1s ease-in infinite alternate;`);

const TileBadge: React.FC = props => {

    let content = props.children;

    if (typeof props.children === "number" && props.children > 99) {
        content = "99+";
    }

    return (
        <CustomBadgeBase>{content}</CustomBadgeBase>
    );
}

export default TileBadge;