import { Field, Form, Formik, FormikConfig } from "formik";
import React from "react";
import * as Yup from "yup";
import { TextField } from "formik-material-ui";
import { AuthForgotPasswordBody } from "@shared/requests/AuthForgotPassword";
import FieldsGroup from "../../components/FieldsGroup";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

interface IForgotPasswordDialogProps {
    isOpen: boolean;
    handleSubmit: (formBody: AuthForgotPasswordBody) => Promise<boolean>;
    handleClose: () => void;
}

const ForgotPasswordDialogSchema = Yup.object({
    username: Yup.string()
        .required(),
    email: Yup.string()
        .email()
        .required()
});

const ForgotPasswordDialog: React.FC<IForgotPasswordDialogProps> = props => {

    const formikConfig: FormikConfig<Yup.InferType<typeof ForgotPasswordDialogSchema>> = {
        initialValues: {
            username: "",
            email: ""
        },
        validationSchema: ForgotPasswordDialogSchema,
        onSubmit: async (values, formikBag) => {

            const success = await props.handleSubmit(values);
            if (!success) {

                formikBag.setErrors({
                    username: "Ihr Kennwort konnte nicht zurückgesetzt werden. Bitte überprüfen Sie Ihren Benutzername und Ihre E-Mail Adresse."
                });
            } else {
                props.handleClose();
            }
        }
    };

    return (
        <Dialog open={props.isOpen} onClose={props.handleClose} aria-labelledby="ForgotPasswordDialog-title">
            <Formik {...formikConfig}>
                {formik => (
                    <Form>
                        <DialogTitle id="ForgotPasswordDialog-title">Passwort wiederherstellen</DialogTitle>
                        <DialogContent>
                            <FieldsGroup>
                                <Field
                                    component={TextField}
                                    name="username"
                                    label="Benutzername / Kunden-Nr."
                                    fullWidth
                                    autoFocus
                                    autoCapitalize="off"
                                    autoCorrect="off"
                                />
                                <Field
                                    component={TextField}
                                    name="email"
                                    label="Im Kundenkonto hinterlegte E-Mail"
                                    fullWidth
                                    autoCapitalize="off"
                                    autoCorrect="off"
                                />
                            </FieldsGroup>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={props.handleClose}>
                                Abbrechen
                            </Button>
                            <Button type="submit" color="primary" disabled={!(formik.isValid && formik.dirty)}>
                                E-Mail senden
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
}

export default ForgotPasswordDialog;