import { normalize, rgb } from "polished";
import { createGlobalStyle } from "styled-components";
import { lightGrey, fontFamily } from "./theme";

export const GlobalNormalize = createGlobalStyle`${normalize()}`;

export const GlobalHtmlStyle = createGlobalStyle({
    html: {
        boxSizing: "border-box",
        overscrollBehaviorY: "none",
        fontFamily: fontFamily,
        fontSize: 16,
        color: rgb(50, 50, 50),
    },
    "html, body, #root": {
        height: "100%"
    },
    "*, *::before, *::after": {
        boxSizing: "inherit"
    },
    "a": {
        color: "inherit",
        cursor: "pointer" // also for links without href attribute
    },
    "h1, h2, h3, h4, h5, h6": {
        margin: 0
    },
    "hr": {
        border: "none",
        height: 2,
        background: lightGrey
    }
});