import React from "react";
import AccountCircle from "@material-ui/icons/AccountCircle";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { UserType } from "@shared/models/UserType";
import IUserInfo from "@shared/interfaces/IUserInfo";
import ToolbarItemContainer from "./ToolbarItemContainer";
import IncognitoIcon from "../../images/IncognitoIcon";
import { paths } from "../../routes";
import useMuiBreakpoint from "../../utils/useMuiBreakpoint";

const SmallerLineTypography = styled(Typography)({
    lineHeight: "1.5"
});

const UserInfoText = styled("div")(props => ({
    position: "relative",
    display: "flex",
    flexDirection: "column",
    textAlign: "right",
    marginRight: props.theme.spacing(1),
    marginLeft: props.theme.spacing(1),
    maxHeight: 50,
    overflow: "hidden"
}));

const ExternalReferenceIcon = styled(IncognitoIcon)({
    verticalAlign: "bottom"
});

function getUserSecondLine(user: IUserInfo): React.ReactNode {

    if (user.type === UserType.customer) {
        return user.username; // customer number
    }

    if (user.externalReference) {
        return (
            <a href={paths.setExternalReference.get()} style={{ textDecoration: "none" }}>
                <ExternalReferenceIcon fontSize="small" color="secondary" />
                {" " + user.externalReference}
            </a>
        );
    }

    return user.type === UserType.admin ? "Administrator" : "Back-Office";
}

interface IAccountKnobProps {
    user: IUserInfo | null;
    onLoginClick: () => void;
    onLogoutClick: () => void;
    onAdminSettingsClick: () => void;
    onChangePasswordClick: () => void;
}

const AccountKnob: React.FC<IAccountKnobProps> = props => {

    const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

    const isSmallDevice = useMuiBreakpoint("down", "xs");

    const handleClick = (event: React.MouseEvent) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onLoginClick = () => {
        setAnchorEl(null);
        props.onLoginClick();
    };

    const onLogoutClick = () => {
        setAnchorEl(null);
        props.onLogoutClick();
    };

    const onChangePasswordClick = () => {
        setAnchorEl(null);
        props.onChangePasswordClick();
    };

    const actionText = props.user ? "Abmelden" : "Anmelden";

    return (
        <ToolbarItemContainer>
            {props.user && !isSmallDevice && (
                <UserInfoText>
                    <SmallerLineTypography display="block" variant="subtitle1">{props.user.displayName}</SmallerLineTypography>
                    <SmallerLineTypography display="block" variant="subtitle2">{getUserSecondLine(props.user)}</SmallerLineTypography>
                </UserInfoText>
            )}
            <Tooltip title={actionText}>
                <IconButton color={props.user ? "inherit" : "default"} onClick={handleClick}>
                    <AccountCircle />
                </IconButton>
            </Tooltip>
            <Menu
                open={!!anchorEl}
                onClose={handleClose}
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}
            >
                <MenuItem onClick={props.user ? onLogoutClick : onLoginClick}>{actionText}</MenuItem>
                {!!props.user && (
                    <MenuItem onClick={onChangePasswordClick}>Kennwort ändern</MenuItem>
                )}
            </Menu>
        </ToolbarItemContainer>
    );
};

export default AccountKnob;