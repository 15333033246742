import { IReactionDisposer, makeAutoObservable, reaction } from "mobx";
import { Inject, Service } from "typedi";
import { Notifications } from "@shared/requests/Notifications";
import { NotificationsRead } from "@shared/requests/NotificationsRead";
import { NotificationsDelete } from "@shared/requests/NotificationsDelete";
import IFileNotification from "@shared/interfaces/IFileNotification";
import startsWithPath from "@shared/utils/startsWithPath";
import { UserRole } from "@shared/models/UserRole";
import { UserType } from "@shared/models/UserType";
import { notificationsToken, notificationsReadToken, notificationsDeleteToken } from "../requests";
import UserService from "./UserService";

@Service()
export default class FileNotificationService {

    private readonly userService: UserService;

    private readonly notificationsRequest: Notifications;
    private readonly notificationsRead: NotificationsRead;
    private readonly notificationsDelete: NotificationsDelete;

    private onUserServiceRefreshDisposer?: IReactionDisposer;
    private onUserServiceLogoutDisposer?: IReactionDisposer;

    private _notifications = new Array<IFileNotification>();

    get notifications() {

        return this._notifications;
    }

    get canModifyNotifications() {

        return (
            this.userService.userInfo?.roles.includes(UserRole.manageUsers) ||
            this.userService.userInfo?.type === UserType.admin
        ) || false;
    }

    constructor(
        @Inject() userService: UserService,
        @Inject(notificationsToken) notifications: Notifications,
        @Inject(notificationsReadToken) notificationsRead: NotificationsRead,
        @Inject(notificationsDeleteToken) notificationsDelete: NotificationsDelete
    ) {
        this.userService = userService;
        this.notificationsRequest = notifications;
        this.notificationsRead = notificationsRead;
        this.notificationsDelete = notificationsDelete;

        makeAutoObservable(this, undefined, { deep: false });
    }

    init() {

        this.onUserServiceRefreshDisposer?.();
        this.onUserServiceRefreshDisposer = reaction(() => this.userService.lastRefresh, () => this.fetchNotifications());

        this.onUserServiceLogoutDisposer?.();
        this.onUserServiceLogoutDisposer = reaction(() => this.userService.userInfo, (user) => {
            if (!user) {
                this._notifications = [];
            }
        });
    }

    hasNotification(fileOrDirPath: string) {

        return this.notifications.some(n => n.path === fileOrDirPath);
    }

    countInDirectory(dirPath: string) {

        return this.notifications.filter(n => startsWithPath(n.path, dirPath));
    }

    async fetchNotifications() {

        if (this.userService.userInfo) {
            
            const response = await this.notificationsRequest.send({}, null, {});
            if (response.success) {

                this._notifications = response.result;
            }
        }
    }

    async read(path: string) {

        if (!this.userService.userInfo) {
            return false;
        }

        const response = await this.notificationsRead.send({}, null, { path });

        if (!response.success) {
            return false;
        }

        await this.fetchNotifications();

        return true;
    }

    async readAll() {

        const response = await this.notificationsRead.send({}, null, {});

        if (!response.success) {
            return false;
        }

        await this.fetchNotifications();

        return true;
    }

    async removeNotification(path: string) {

        if (!this.canModifyNotifications) {
            return false;
        }

        const response = await this.notificationsDelete.send({}, null, { path });

        if (!response.success) {
            return false;
        }

        this.fetchNotifications();

        return true;
    }
}