import { PropertyErrors } from "../interfaces/PropertyErrors";

export interface ISuccessResponse<R> {
    result: R;
    success: true;
}

export interface IErrorResponse {
    success: false;
    error: string;
    propertyErrors?: PropertyErrors<any>
}

export type ResponseType<R> = ISuccessResponse<R> | IErrorResponse & { status: number };

export enum HttpMethod {
    get = "GET",
    post = "POST",
    delete = "DELETE",
    put = "PUT",
    patch = "PATCH"
}

export type RequestDefinition<
    TPath extends string,
    TMethod extends HttpMethod,
    TParams extends Record<string, any>,
    TPost,
    TSearch extends Record<string, any>,
    TResult
    > = {
        path: TPath;
        method: TMethod;
        result?: TResult;
        send: (params: TParams, postData: TPost, query: TSearch) => Promise<ResponseType<TResult>>,
        abort: () => void;
    }