import { Inject, Service } from "typedi";
import INewsResponseItem from "@shared/interfaces/INewsResponseItem";
import { INewsCreateBody, NewsCreate } from "@shared/requests/NewsCreate";
import { NewsDelete } from "@shared/requests/NewsDelete";
import { NewsGetForPath } from "@shared/requests/NewsGetForPath";
import { NewsPatch } from "@shared/requests/NewsPatch";
import { newsCreateToken, newsDeleteToken, newsGetForPathToken, newsPatchToken } from "../requests";

@Service()
export default class NewsService {

    private readonly newsGetForPathRequest: NewsGetForPath;
    private readonly newsCreateRequest: NewsCreate;
    private readonly newsPatchRequest: NewsPatch;
    private readonly newsDeleteRequest: NewsDelete;

    constructor(
        @Inject(newsGetForPathToken) newsGetForPathRequest: NewsGetForPath,
        @Inject(newsCreateToken) newsCreateRequest: NewsCreate,
        @Inject(newsPatchToken) newsPatchRequest: NewsPatch,
        @Inject(newsDeleteToken) newsDeleteRequest: NewsDelete
    ) {
        this.newsGetForPathRequest = newsGetForPathRequest;
        this.newsCreateRequest = newsCreateRequest;
        this.newsPatchRequest = newsPatchRequest;
        this.newsDeleteRequest = newsDeleteRequest;
    }

    async getForPath(path: string): Promise<Array<INewsResponseItem>> {

        const response = await this.newsGetForPathRequest.send({}, null, { path });

        return response.success && response.result || [];
    }

    async create(fields: INewsCreateBody) {

        const response = await this.newsCreateRequest.send({}, fields, {});

        return response.success && response.result || null;
    }

    async update(id: string, fields: Partial<INewsCreateBody>) {

        const response = await this.newsPatchRequest.send({ id }, fields, {});

        return response.success && response.result || null;
    }

    async delete(id: string) {

        const response = await this.newsDeleteRequest.send({ id }, null, {});

        return response.success;
    }
}