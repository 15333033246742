import { makeAutoObservable } from "mobx";
import type IClosable from "./IClosable";

export default class ContextualConfirmActionState<T> implements IClosable {

    private otherStatesToClose = new Array<IClosable>();

    private confirmAction: (item: T) => void;

    private _currentItem: T | null = null;

    get isOpen() {
        return !!this._currentItem;
    }

    get currentItem() {
        return this._currentItem;
    }

    constructor(confirmAction: (item: T) => void) {

        this.confirmAction = confirmAction;

        makeAutoObservable(this, undefined, { autoBind: true });
    }

    open(item: T) {
        this.otherStatesToClose.forEach(s => s.close());
        this._currentItem = item;
    }

    close() {
        this._currentItem = null;
    }

    confirm() {

        if (this._currentItem) {
            this.confirmAction(this._currentItem);
            this._currentItem = null;
        }
    }

    closesOther(otherState: IClosable) {

        this.otherStatesToClose.push(otherState);
    }
}