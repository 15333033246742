import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Slide, { SlideProps } from "@material-ui/core/Slide";
import styled from "styled-components";
import AppBar, { AppBarProps } from "@material-ui/core/AppBar";

const Transition = React.forwardRef<typeof Slide, SlideProps>((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const FullscreenOpenerContent = styled.div({
    flexGrow: 1,
    position: "relative"
});

interface IFullscreenDialogProps {
    open: boolean;
    onClose: () => void;
    header: React.ReactNode;
    AppBar?: React.ComponentType<AppBarProps>;
}

const FullscreenDialog: React.FC<IFullscreenDialogProps> = props => {

    const AppBarComponent = props.AppBar || AppBar;

    return (
        <Dialog fullScreen open={props.open} onClose={props.onClose} TransitionComponent={Transition}>
            <AppBarComponent position="relative">
                {props.header}
            </AppBarComponent>
            <FullscreenOpenerContent>
                {props.children}
            </FullscreenOpenerContent>
        </Dialog>
    );
}

export default FullscreenDialog;