import React from "react";
import loadable from "@loadable/component";
import useMuiBreakpoint from "../../utils/useMuiBreakpoint";
import MainNavigationDropDown from "./MainNavigationDropDown";
import IBrowseDirectoryInfo from "@shared/interfaces/IBrowseDirectoryInfo";

const MainNavigationList = loadable(() => import("./MainNavigationList"));

interface IMainNavigationProps {
    rooDirectories: Array<IBrowseDirectoryInfo>;
    currentPath: string;
    onClick: (dir: IBrowseDirectoryInfo) => void;
    menuOpen?: boolean;
}

const MainNavigation: React.FC<IMainNavigationProps> = props => {

    const isSmallDevice = useMuiBreakpoint("down", "xs");

    const menuItems: Array<[string, IBrowseDirectoryInfo]> = props.rooDirectories.map(dir => [dir.name, dir]);

    if (props.rooDirectories.length === 0) {
        return null;
    }

    return isSmallDevice ? (
        <MainNavigationDropDown
            activePath={props.currentPath}
            items={menuItems}
            onClick={props.onClick}
            open={!!props.menuOpen}
        />
    ) : (
        <MainNavigationList
            activePath={props.currentPath}
            items={menuItems}
            onClick={props.onClick}
        />
    );
};

export default MainNavigation;