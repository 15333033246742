import styled from "styled-components";
import Typography from "@material-ui/core/Typography";

const PageHeadline = styled(Typography)(props => ({
    marginBottom: props.theme.spacing(3)
}));

PageHeadline.defaultProps = PageHeadline.defaultProps || {};
PageHeadline.defaultProps!.variant = "h5";

export default PageHeadline;