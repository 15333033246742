import styled from "styled-components";
import Container from "@material-ui/core/Container";

const PageContainer = styled(Container)(props => ({
    marginTop: props.theme.spacing(4),
    marginBottom: props.theme.spacing(6),
    [props.theme.breakpoints.down("xs")]: {
        marginTop: props.theme.spacing(2),
        marginBottom: props.theme.spacing(4)
    },
    position: "relative"
}));

export default PageContainer;