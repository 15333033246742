import React from "react";
import Link from "@material-ui/core/Link";
import { useHistory } from "react-router-dom";

type TextLinkColor = "inherit" | "primary" | "secondary" | "textPrimary" | "textSecondary" | "error";

interface ITextLinkProps {
    to: string;
    color?: TextLinkColor;
}

const TextLink: React.FC<ITextLinkProps> = props => {

    const history = useHistory();

    const onClick = (e: React.MouseEvent) => {
        e.preventDefault();
        history.push(props.to);
    };

    return (
        <Link color={props.color} href={props.to} onClick={onClick}>{props.children}</Link>
    );
}

export default TextLink;