import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { RouteConfigComponentProps } from "react-router-config";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import loadable from "@loadable/component";
import Typography from "@material-ui/core/Typography";
import Tile from "../../components/browse/Tile";
import TilesGrid from "../../components/browse/TilesGrid";
import FlowGroup from "../../components/FlowGroup";
import PageContainer from "../../components/PageContainer";
import PageHeadline from "../../components/PageHeadline";
import ProgressBox from "../../components/ProgressBox";
import NewsSidebar from "../../components/news/NewsSidebar";
import { Fill } from "../../components/Slot";
import { EditTileAction } from "../../models/EditTileAction";
import useViewModel from "../../utils/useViewModel";
import useMuiBreakpoint from "../../utils/useMuiBreakpoint";
import getAutoDeleteDays from "../../utils/getAutoDeleteDays";
import BreadcrumbMenu from "./BreadcrumbMenu";
import BrowseViewModel from "./BrowseViewModel";
import AddKnob from "./AddKnob";
import { BrowseFilesListViewModel } from "./BrowseFilesListViewModel";

const BrowseContainer = styled.div(props => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    "& > *": {
        flexGrow: 1
    },
    "&:not(:last-child)": {
        marginBottom: props.theme.spacing(3)
    }
}));

const IconPickerDialog = loadable(() => import("../../components/icon-picker/IconPickerDialog"));
const EditRestrictDialog = loadable(() => import("../../components/edit-restrict/EditRestrictDialog"));
const EditAutoDeleteDialog = loadable(() => import("../../components/edit-autoDelete/EditAutoDeleteDialog"));
const NewsKnob = loadable(() => import("../../components/news/NewsKnob"));
const BrowseFilesList = loadable(() => import("./BrowseFilesList"));
const ContactDialog = loadable(() => import("../../components/contact/ContactDialog"));
const NewsDialog = loadable(() => import("../../components/news/NewsDialog"));

interface IBrowsePageParams {
    "0": string;
}

const Browse = observer<RouteConfigComponentProps<IBrowsePageParams>>(props => {

    const viewModel = useViewModel(BrowseViewModel);
    const browseFilesListViewModel = useViewModel(BrowseFilesListViewModel);
    const history = useHistory();

    const isSmallDevice = useMuiBreakpoint("down", "xs");

    const path = props.match.params["0"] || "/";

    useEffect(() => {
        viewModel.initWithPath(path);
    }, [path, viewModel.userIsLoggedIn]);

    useEffect(() => {

        const openFile = (new URLSearchParams(props.location.search)).get("open");
        if (openFile) {
            setTimeout(() => {
                browseFilesListViewModel.openFile(openFile);
            }, 1000); // BrowseService won't have loaded currentDir quicker 
        }

        history.replace(props.location.pathname);

    }, [path]);

    const directory = viewModel.currentDir;
    const isRootDirectory = viewModel.isAtStartDir || directory?.parents.length === 0;

    const hasChildren = directory && directory.children.length > 0;
    const hasContents = directory && (directory.contents.length > 0 || !!directory.markdownContent);
    const hasNews = viewModel.news.length > 0;

    const showNewsBeneathContents = !hasChildren && hasNews;

    const breadCrumbDirs = directory?.parents || [];

    return (
        <PageContainer>
            {directory ? (
                <>
                    {!isRootDirectory && !viewModel.isAtStartDir && (
                        <FlowGroup>
                            <BreadcrumbMenu
                                parents={breadCrumbDirs}
                                current={directory.info}
                                onItemClick={viewModel.onDirectoryClick}
                                hide={isRootDirectory}
                            />
                        </FlowGroup>
                    )}
                    {directory.parents.length > 0 && directory.info.title && (
                        <PageHeadline>{directory.info.title}</PageHeadline>
                    )}
                    {hasChildren && (
                        <BrowseContainer>
                            {hasNews && !isSmallDevice && !showNewsBeneathContents && (
                                <NewsSidebar news={viewModel.news} />
                            )}
                            <TilesGrid>
                                {directory.children.map((child, index) => (
                                    <Tile
                                        key={child.path}
                                        info={child}
                                        notificationCount={viewModel.getDirNotificationCount(child)}
                                        onClick={viewModel.onDirectoryClick}
                                        onEdit={viewModel.onEditDirectory}
                                        canMoveLeft={viewModel.canEditTileAppearance && (index > 0)}
                                        canMoveRight={viewModel.canEditTileAppearance && ((index + 1) < directory.children.length)}
                                        canSetIcon={viewModel.canEditTileAppearance}
                                        canSetAutoDelete={viewModel.canSetAutoDelete}
                                        canEditRestrict={viewModel.canEditRestrict}
                                        showDirInfos={viewModel.showDirInfos}
                                    />
                                ))}
                            </TilesGrid>
                        </BrowseContainer>
                    )}
                    {hasContents && (
                        <BrowseFilesList
                            sidebar={hasNews && !isSmallDevice && showNewsBeneathContents && <NewsSidebar news={viewModel.news} />}
                            twoColumns={!isSmallDevice && !(hasNews && showNewsBeneathContents)}
                            fileGroups={viewModel.getFilesInGroups()}
                        />
                    )}
                    {!hasChildren && !hasContents && (
                        <Typography variant="h6" color="textSecondary">Hier gibt es noch keine Inhalte</Typography>
                    )}
                    {viewModel.showEditDialogs && (
                        <AddKnob
                            handleUploadFile={viewModel.handleUploadFile}
                            onAddContactClick={viewModel.addContactDialog.open}
                            onAddNewsClick={viewModel.canEditAnnouncements ? viewModel.addNewsDialog.open : undefined}
                        />
                    )}
                </>
            ) : (
                <ProgressBox />
            )}
            {viewModel.showEditDialogs && (
                <>
                    <IconPickerDialog
                        isOpen={viewModel.editingAction === EditTileAction.icon}
                        handleClose={viewModel.cancelEditDirectory}
                        selected={viewModel.editingDirectory?.icon || null}
                        onSelectConfirm={viewModel.onSelectIconConfirm}
                    />
                    <EditRestrictDialog
                        isOpen={viewModel.editingAction === EditTileAction.restrict}
                        handleClose={viewModel.cancelEditDirectory}
                        restrict={viewModel.editingDirectory?.restrict || null}
                        onEditConfirm={viewModel.onEditRestrictConfirm}
                    />
                    <EditAutoDeleteDialog
                        isOpen={viewModel.editingAction === EditTileAction.autoDelete}
                        handleClose={viewModel.cancelEditDirectory}
                        autoDeleteDays={viewModel.editingDirectory && getAutoDeleteDays(viewModel.editingDirectory) || 0}
                        onEditConfirm={viewModel.onEditAutoDeleteConfirm}
                    />
                    <ContactDialog
                        title="Kontakt hinzufügen"
                        confirmButtonLabel="erstellen"
                        isOpen={viewModel.addContactDialog.isOpen}
                        handleSubmit={viewModel.onCreateContactSubmit}
                        handleClose={viewModel.addContactDialog.close}
                    />
                    <NewsDialog
                        isOpen={viewModel.addNewsDialog.isOpen}
                        title="Information hinzufügen"
                        confirmButtonText="hinzufügen"
                        data={{ path: viewModel.currentDir?.info.path }}
                        handleClose={viewModel.addNewsDialog.close}
                        handleSubmit={viewModel.createNews}
                        pathAutocompleteState={viewModel.pathAutocompleteState}
                    />
                </>
            )}
            {hasNews && isSmallDevice && (
                <Fill id="news">
                    <NewsKnob news={viewModel.news} atEndEdge={true} />
                </Fill>
            )}
        </PageContainer>
    );
});

export default Browse;