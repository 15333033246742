import React, { useEffect, useState } from "react";
import { renderRoutes } from "react-router-config";
import { observer } from "mobx-react-lite";
import { TransitionGroup } from "react-transition-group";
import styled from "styled-components";
import Slide from "@material-ui/core/Slide";
import loadable from "@loadable/component";
import { PageComponent } from "../../types/PageComponent";
import useViewModel from "../../utils/useViewModel";
import OffPageMenu from "../../components/OffPageMenu";
import BackOfficeMenuContent from "./BackOfficeMenuContent";
import ConfirmDialog from "../../components/ConfirmDialog";
import ForgotPasswordDialog from "./ForgotPasswordDialog";
import NotificationsContainer from "../../components/notifications/NotificationsContainer";
import NotificationItem from "../../components/notifications/NotificationItem";
import TextLink from "../../components/TextLink";
import { paths } from "../../routes";
import MainMenu from "./MainMenu";
import LayoutViewModel from "./LayoutViewModel";
import LoginDialog from "./LoginDialog";

const AnnouncementPopup = loadable(() => import("../../components/announcement/AnnouncementPopup"));

const ContentContainer = styled.div({
    flexGrow: 1,
    position: "relative",
    display: "flex",
    flexDirection: "column"
});

const FooterContainer = styled.div(props => ({
    paddingLeft: props.theme.spacing(3),
    paddingRight: props.theme.spacing(3),
    background: props.theme.palette.grey[200],
    color: props.theme.palette.getContrastText(props.theme.palette.grey[200]),
    fontSize: "0.8em",
    lineHeight: 2
}));

const Layout: PageComponent = observer(props => {

    const viewModel = useViewModel(LayoutViewModel);

    const [renderAnnouncements, setRenderAnnouncements] = useState(viewModel.unreadAnnouncements.length > 0);

    useEffect(() => {
        if (!renderAnnouncements && viewModel.unreadAnnouncements.length > 0) {
            setRenderAnnouncements(true);
        }
    });

    const menuContent = <BackOfficeMenuContent />;

    return (
        <OffPageMenu
            open={viewModel.backOfficeSideMenu.isOpen}
            onClose={viewModel.backOfficeSideMenu.close}
            menuWidth={260}
            menuContent={menuContent}
        >
            <MainMenu />
            <LoginDialog
                isOpen={viewModel.loginDialog.isOpen}
                handleClose={viewModel.loginDialog.close}
                handleSubmit={viewModel.login}
                onForgotPasswordClick={viewModel.forgotPasswordDialog.open}
            />
            <ForgotPasswordDialog
                isOpen={viewModel.forgotPasswordDialog.isOpen}
                handleSubmit={viewModel.sendForgottenPasswordMail}
                handleClose={viewModel.forgotPasswordDialog.close}
            />
            <ConfirmDialog
                title="Kennwort ändern"
                isOpen={viewModel.changePasswordDialog.isOpen}
                onConfirm={viewModel.changePasswordDialog.confirm}
                onAbort={viewModel.changePasswordDialog.close}
            >
                Mit Bestätigen erhalten Sie eine E-Mail mit der Sie ein anderes Kennwort wählen können.
            </ConfirmDialog>
            {renderAnnouncements && (
                <AnnouncementPopup
                    announcement={viewModel.unreadAnnouncements[0] || null}
                    onDismiss={viewModel.dismissAnnouncement}
                />
            )}
            <NotificationsContainer>
                <TransitionGroup>
                    {viewModel.notifications.map(n => (
                        <Slide key={n.id} direction="right" mountOnEnter unmountOnExit>
                            <NotificationItem onClose={() => viewModel.closeNotification(n.id)} {...n}>
                                {n.content}
                            </NotificationItem>
                        </Slide>
                    ))}
                </TransitionGroup>
            </NotificationsContainer>
            <ContentContainer>
                {!!props.route && viewModel.isLoginStatusKnown && renderRoutes(props.route.routes)}
            </ContentContainer>
            <FooterContainer>
                <TextLink to={paths.imprint.get()} color="textPrimary">Impressum</TextLink>
            </FooterContainer>
        </OffPageMenu>
    );
});

export default Layout;