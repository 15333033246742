import { Service } from "typedi";
import { FileCategory } from "../models/FileCategory";
import { FileDisplayMode } from "../models/FileDisplayMode";

@Service()
export default class BrowseFileService {

    private static readonly fileCategoryToExtensionsMap: Record<FileCategory, Array<string>> = {
        [FileCategory.uncommon]: [],
        [FileCategory.document]: ["doc", "docx", "odt", "pdf", "rtf", "tex", "txt", "wpd", "md"],
        [FileCategory.image]: ["jpg", "png", "gif", "bmp", "webp", "svg"],
        [FileCategory.audio]: ["m4a", "flac", "mp3", "wav", "wma", "aac"],
        [FileCategory.video]: ["mp4", "mov", "wmv", "avi", "flv", "webm", "mpg"],
        [FileCategory.contact]: ["vcf"]
    };

    private static readonly fileCategoryToReadableNameMap: Record<FileCategory, string | undefined> = {
        [FileCategory.uncommon]: undefined,
        [FileCategory.document]: "Dokument",
        [FileCategory.image]: "Grafik",
        [FileCategory.audio]: "Audio",
        [FileCategory.video]: "Video",
        [FileCategory.contact]: "Kontakt"
    };

    private static readonly commonFileTypeReadableNames: Record<string, string> = {
        pdf: "PDF",
        doc: "Word",
        docx: "Word",
        xls: "Excel",
        xlsx: "Excel",
        ppt: "PowerPoint",
        pptx: "PowerPoint",
        md: "Dokument",
        html: "Webseite",
        jpg: "Bild",
        txt: "Textdatei",
        url: "Weblink",
        ics: "Kalendereintrag",
        vcf: "Visitenkarte",
        eml: "E-Mail",
        ico: "Icon",
        psd: "Photoshop",
        ai: "Illustrator",
        indd: "InDesign"
    };

    getTypeName(type: string) {

        const typeFromCommon = BrowseFileService.commonFileTypeReadableNames[type];

        if (typeFromCommon) {
            return typeFromCommon;
        }
    
        const category = this.getCategory(type);
    
        return this.getCategoryLabel(category) || type.toUpperCase();
    }

    getCategory(type: string) {

        for (const categoryKey in BrowseFileService.fileCategoryToExtensionsMap) {
            
            const category = +categoryKey as FileCategory;
            const extensions = BrowseFileService.fileCategoryToExtensionsMap[category];

            if (extensions.includes(type)) {
                return category;
            }
        }

        return FileCategory.uncommon;
    }

    getCategoryLabel(category: FileCategory) {

        return BrowseFileService.fileCategoryToReadableNameMap[category];
    }

    getDisplayMode(type: string): FileDisplayMode {

        if (type === "md") {
            return FileDisplayMode.markdown;
        }

        if (type === "txt" || type === "html") {
            return FileDisplayMode.frameContent;
        }

        if (type === "vcf") {
            return FileDisplayMode.contactPopup;
        }

        if (type === "pdf") {

            // don't open custom frame since touch devices may likely have native PDF viewers
            // that support swipe gestures for multi-page documents
            if (this.isTouchDevice()) {
                return FileDisplayMode.open;
            }

            return FileDisplayMode.frameContent;
        }

        if (BrowseFileService.fileCategoryToExtensionsMap[FileCategory.image].includes(type)) {
            return FileDisplayMode.image;
        }

        if (BrowseFileService.fileCategoryToExtensionsMap[FileCategory.video].includes(type)) {
            return FileDisplayMode.video;
        }

        return FileDisplayMode.download;
    }

    private isTouchDevice() {

        if (matchMedia("(pointer:coarse)").matches) {

            return true;
        }

        // IOS Safari does not seem to support the media query
        const iosDeviceTypes = ["iPhone", "iPad", "iPod"];
        if (iosDeviceTypes.includes(navigator.platform) || iosDeviceTypes.includes(navigator.userAgent)) {

            return true;
        }

        return false;
    }
}