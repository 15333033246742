import { Inject, Service } from "typedi";
import { IReactionDisposer, makeAutoObservable, reaction } from "mobx";
import addSeconds from "date-fns/addSeconds";
import { AnnouncementDelete } from "@shared/requests/AnnouncementDelete";
import { AnnouncementPut, IAnnouncementPutBody } from "@shared/requests/AnnouncementPut";
import { AnnouncementActive } from "@shared/requests/AnnouncementActive";
import IAnnouncementResponseItem from "@shared/interfaces/IAnnouncementResponseItem";
import { announcementActiveToken, announcementDeleteToken, announcementPutToken } from "../requests";
import LocalObjectStorage from "../models/LocalObjectStorage";
import UserService from "./UserService";

@Service()
export default class AnnouncementsService {

    private readonly dismissExpireSeconds = 60 * 60 * 4; // 4h

    private readonly announcementActiveRequest: AnnouncementActive;
    private readonly announcementPutRequest: AnnouncementPut;
    private readonly announcementDeleteRequest: AnnouncementDelete;

    private readonly userService: UserService;

    private onUserServiceRefreshDisposer?: IReactionDisposer;
    private onUserServiceLogoutDisposer?: IReactionDisposer;

    private dismissedAnnouncementsStore = new LocalObjectStorage<Record<string, number>>(
        "announcements_dismiss",
        {},
        this.isAnnouncementDismissEntry
    );

    private _activeAnnouncements = new Array<IAnnouncementResponseItem>();

    private dismissStoreEntries: Record<string, number> = {};

    get activeAnnouncements() {
        return this._activeAnnouncements;
    }

    get unreadAnnouncements() {

        return this.filterOutDismissedAnnouncements(
            this._activeAnnouncements,
            this.dismissStoreEntries
        );
    }

    constructor(
        @Inject(announcementActiveToken) announcementActiveRequest: AnnouncementActive,
        @Inject(announcementPutToken) announcementPutRequest: AnnouncementPut,
        @Inject(announcementDeleteToken) announcementDeleteRequest: AnnouncementDelete,
        @Inject() userService: UserService
    ) {
        this.announcementActiveRequest = announcementActiveRequest;
        this.announcementPutRequest = announcementPutRequest;
        this.announcementDeleteRequest = announcementDeleteRequest;
        this.userService = userService;

        makeAutoObservable(this, undefined, { autoBind: true });
    }

    async init() {

        this.onUserServiceRefreshDisposer?.();
        this.onUserServiceRefreshDisposer = reaction(() => this.userService.lastRefresh, () => {
            if (this.userService.userInfo) {
                this.fetchActiveAnnouncements()
            }
        });

        this.onUserServiceLogoutDisposer?.();
        this.onUserServiceLogoutDisposer = reaction(() => this.userService.userInfo, (user) => {
            if (!user) {
                this.dispose();
            }
        });

        this.dismissStoreEntries = this.dismissedAnnouncementsStore.read();
    }

    dispose() {

        this._activeAnnouncements = [];
        this.dismissStoreEntries = {};
    }

    async create(fields: IAnnouncementPutBody) {

        return this.announcementPutRequest.send({}, fields, {});
    }

    async update(id: string, fields: IAnnouncementPutBody) {

        return this.announcementPutRequest.send({ id }, fields, {});
    }

    async delete(id: string) {

        const response = await this.announcementDeleteRequest.send({ id }, null, {});

        return response.success;
    }

    dismiss(item: IAnnouncementResponseItem, refresh = true) {

        const expireTimeStamp = addSeconds(new Date(), this.dismissExpireSeconds).getTime();

        const dismissStoreEntries = { ...this.dismissStoreEntries };
        dismissStoreEntries[item.id] = expireTimeStamp;

        this.removeOutdatedEntries(dismissStoreEntries);

        this.dismissedAnnouncementsStore.write(dismissStoreEntries);

        if (refresh) {
            this.fetchActiveAnnouncements();
        }
    }

    unread(announcement: IAnnouncementResponseItem) {

        const newDismissStoreEntries = { ...this.dismissStoreEntries };
        delete newDismissStoreEntries[announcement.id];

        this.dismissStoreEntries = newDismissStoreEntries;
        this.dismissedAnnouncementsStore.write(newDismissStoreEntries);
    }

    private async fetchActiveAnnouncements() {

        const response = await this.announcementActiveRequest.send({}, null, {});
        if (response.success) {

            this.dismissStoreEntries = this.dismissedAnnouncementsStore.read();
            this._activeAnnouncements = response.result;

            return true;
        }

        return false;
    }

    private isAnnouncementDismissEntry(value: unknown): value is Record<string, number> {

        if (typeof value !== "object") {
            return false
        }

        const obj = value as Record<string, number>;

        const keys = Object.keys(obj);

        if (keys.some(k => isNaN(obj[k]))) {
            return false;
        }

        return true;
    }

    private removeOutdatedEntries(dismissStoreEntries: Record<string, number>) {

        const now = new Date();

        try {
            Object.keys(dismissStoreEntries).forEach(key => {
                const expireDate = new Date(dismissStoreEntries[key]);
                if (now > expireDate) {
                    delete dismissStoreEntries[key];
                }
            });
        } catch (error) {
            console.error("Failed to remove outdated dismiss announcement entries");
        }
    }

    private filterOutDismissedAnnouncements(items: Array<IAnnouncementResponseItem>, dismissStoreEntries: Record<string, number>) {

        const now = new Date();

        return items.filter(item => {

            const dismissEntryExpiryTimestamp = dismissStoreEntries[item.id];
            if (dismissEntryExpiryTimestamp) {
                return now > new Date(dismissEntryExpiryTimestamp);
            }

            return true;
        });
    }
}