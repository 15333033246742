import React, { useEffect, useState } from "react";
import loadable from "@loadable/component";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import NotificationsIcon from "@material-ui/icons/Notifications";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import Badge from "@material-ui/core/Badge";
import IFileNotification from "@shared/interfaces/IFileNotification";
import ToolbarItemContainer from "./ToolbarItemContainer";
import IAnnouncementResponseItem from "@shared/interfaces/IAnnouncementResponseItem";

const NotificationsListPopover = loadable(() => import("../../components/notifications/NotificationsListPopover"));

interface IFileNotificationsKnobProps {
    announcements: Array<IAnnouncementResponseItem>;
    fileNotifications: Array<IFileNotification>;
    onDirectoryClick: (path: string) => void;
    onAnnouncementClick: (announcement: IAnnouncementResponseItem) => void;
    onDismissFileNotificationClick: (dirPath: string, file: string) => void;
    onDismissFileNotificationsDirClick: (dirPath: string) => void;
    onDismissAllClick?: () => void;
    atEndEdge?: boolean;
}

const FileNotificationsKnob: React.FC<IFileNotificationsKnobProps> = props => {

    const [anchorEl, setAnchorEl] = useState<Element | null>(null);

    const [dismissAllConfirmed, setDismissAllConfirmed] = useState(false);

    useEffect(() => {
        if (!anchorEl) {
            setDismissAllConfirmed(false);
        }
    }, [anchorEl]);

    const notificationsCount = props.fileNotifications.length + props.announcements.length;
    const hasNotifications = notificationsCount > 0;

    const handleClick = (event: React.MouseEvent) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDirectoryClick = (path: string) => {
        setAnchorEl(null);
        props.onDirectoryClick(path);
    };

    const onAnnouncementClick = (announcement: IAnnouncementResponseItem) => {
        setAnchorEl(null);
        props.onAnnouncementClick(announcement);
    };

    const onDismissAllClick = !!props.onDismissAllClick ? () => {

        if (dismissAllConfirmed) {
            setAnchorEl(null);
            props.onDismissAllClick?.();
        } else {
            setDismissAllConfirmed(true);
        }
    } : undefined;

    const edge = props.atEndEdge ? "end" : undefined;

    const noNotificationsButton = (
        <IconButton
            color="inherit"
            edge={edge}
            disabled={true}
        >
            <NotificationsNoneIcon />
        </IconButton>
    );

    const notificationsButton = hasNotifications ? (
        <Tooltip title="Benachrichtigungen">
            <IconButton
                color="inherit"
                edge={edge}
                onClick={handleClick}
            >
                <Badge badgeContent={notificationsCount} color="primary">
                    <NotificationsIcon />
                </Badge>
            </IconButton>
        </Tooltip>
    ) : null; // performance opt.

    return (
        <ToolbarItemContainer>
            {hasNotifications ? notificationsButton : noNotificationsButton}
            {hasNotifications && (
                <NotificationsListPopover
                    announcements={props.announcements}
                    fileNotifications={props.fileNotifications}
                    anchorElement={anchorEl}
                    onClose={handleClose}
                    onDirectoryClick={handleDirectoryClick}
                    onAnnouncementClick={onAnnouncementClick}
                    onDismissFileNotificationClick={props.onDismissFileNotificationClick}
                    onDismissFileNotificationsDirClick={props.onDismissFileNotificationsDirClick}
                    onDismissAllClick={onDismissAllClick}
                    dismissAllConfirmed={dismissAllConfirmed}
                />
            )}
        </ToolbarItemContainer>
    );
}

export default FileNotificationsKnob;