import React from "react";

interface ISplitStackProps {
    splitBy: React.ReactNode;
}

const SplitStack: React.FC<ISplitStackProps> = props => {

    const children = React.Children.toArray(props.children);
    const childrenWithSplitElements = [children.shift()];

    children.forEach((child, index) => {

        const separator = <React.Fragment key={`s_${index}`}>{props.splitBy}</React.Fragment>;

        childrenWithSplitElements.push(separator);
        childrenWithSplitElements.push(child);
    });

    return <React.Fragment>{childrenWithSplitElements}</React.Fragment>;
};

export default SplitStack;
