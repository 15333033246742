import React, { useEffect, useState } from "react";
import { connect, FormikContextType } from "formik";
import { Alert } from "@material-ui/lab";
import styled from "styled-components";

const AlertWithMargin = styled(Alert)(props => ({
    marginBottom: props.theme.spacing(2)
}));

interface IOnSubmitValidationErrorProps {
    formik: FormikContextType<any>;
    error: string;
    className?: string;
}

const OnSubmitValidationError: React.FC<IOnSubmitValidationErrorProps> = props => {

    const formik = props.formik;
    const [show, setShow] = useState(false);
    
    useEffect(() => {
        if (formik.submitCount > 0 && !formik.isSubmitting && !formik.isValid) {
            setShow(true);
        }
    }, [formik.submitCount, formik.isSubmitting]);

    return show ? (
        <AlertWithMargin severity="warning" className={props.className}>{props.error}</AlertWithMargin>
    ) : null;
}

export default connect(OnSubmitValidationError) as React.FC<Omit<IOnSubmitValidationErrorProps, "formik">>;