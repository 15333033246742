import replaceTrailing from "./replaceTrailing";

export default function getParentBrowsePath(path: string) {

    path = replaceTrailing(path, "/", "");

    const segments = path.split("/");
    segments.pop();

    return segments.join("/") || "/";
}