import React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import BusinessIcon from "@material-ui/icons/Business";
import HelpIcon from "@material-ui/icons/Help";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import EditAttributesIcon from "@material-ui/icons/EditAttributes";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import NewReleasesIcon from "@material-ui/icons/NewReleases";
import { paths } from "../../routes";
import IncognitoIcon from "../../images/IncognitoIcon";
import RoutePath from "../../utils/RoutePath";
import useViewModel from "../../utils/useViewModel";
import UserService from "../../services/UserService";

function createLinkItem(icon: React.ReactElement, text: string, path: RoutePath<undefined>): IMenuItem | null {

    const history = useHistory();
    const userService = useViewModel(UserService);
    const active = !!useRouteMatch(path.template);

    if (!path.hasAccess(userService.userInfo)) {
        return null;
    }

    return {
        icon,
        text,
        onClick: () => history.push(path.get()),
        active
    };
}

export interface IMenuItem {
    icon: React.ReactElement;
    text: string;
    onClick: () => void;
    active: boolean;
}

const BackOfficeMenuContent: React.FC<{ children?: never }> = () => {

    const menuItems: Array<IMenuItem> = [
        createLinkItem(
            <BusinessIcon />,
            "Kunden-Accounts",
            paths.customerAccounts
        ),
        createLinkItem(
            <AccountBoxIcon />,
            "BackOffice-Accounts",
            paths.backOfficeAccounts
        ),
        createLinkItem(
            <EditAttributesIcon />,
            "Account-Attribute",
            paths.manageAttributes
        ),
        createLinkItem(
            <NotificationsActiveIcon />,
            "Benachrichtigungen verwalten",
            paths.manageNotifications
        ),
        createLinkItem(
            <AnnouncementIcon />,
            "Wichtige Infos verwalten",
            paths.manageNews
        ),
        createLinkItem(
            <NewReleasesIcon />,
            "Wichtige Ankündigungen",
            paths.manageAnnouncements
        ),
        createLinkItem(
            <IncognitoIcon />,
            "Als Kunde maskieren",
            paths.setExternalReference
        ),
        createLinkItem(
            <HelpIcon />,
            "Benutzerhandbuch", paths.docs
        ),
        createLinkItem(
            <CreateNewFolderIcon />,
            "Netzlaufwerk installieren",
            paths.setupNetworkDrive
        )
    ].filter((item: IMenuItem | null): item is IMenuItem => !!item);

    return (
        <List>
            {menuItems.map(item => (
                <ListItem key={item.text} button onClick={item.onClick} selected={item.active}>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.text} />
                </ListItem>
            ))}
        </List>
    );
};

export default BackOfficeMenuContent;