import React, { useState } from "react";
import { Alert } from "@material-ui/lab";
import { TextField } from "formik-material-ui";
import { Field, FormikConfig, Form } from "formik";
import * as Yup from "yup";
import { Formik } from "formik";
import OnSubmitValidationError from "../../utils/OnSubmitValidationError";
import FieldsGroup from "../../components/FieldsGroup";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";

interface ILoginDialogProps {
    isOpen: boolean;
    handleClose: () => void;
    handleSubmit: (username: string, password: string) => Promise<{ success: boolean, error?: string }>;
    onForgotPasswordClick: () => void;
    children?: never;
}

const LoginDialogSchema = Yup.object({
    username: Yup.string().required(),
    password: Yup.string().required()
});

const LoginDialog: React.FC<ILoginDialogProps> = props => {

    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const formikConfig: FormikConfig<Yup.InferType<typeof LoginDialogSchema>> = {
        initialValues: {
            username: "",
            password: ""
        },
        validationSchema: LoginDialogSchema,
        onSubmit: async (values, formikBag) => {

            const { success, error } = await props.handleSubmit(values.username, values.password);

            if (!success) {

                formikBag.setErrors({
                    username: "Fehler",
                    password: "Fehler"
                });

                if (error === "account_locked") {
                    setErrorMessage("Anmeldung nicht möglich, ihr Account wurde gesperrt.");
                } else {
                    setErrorMessage("Anmeldung fehlgeschlagen! Bitte überprüfen Sie Ihre Anmeldedaten.");
                }

            } else {
                setErrorMessage(null);
                props.handleClose();
            }
        }
    };

    return (
        <Dialog open={props.isOpen} onClose={props.handleClose} aria-labelledby="login-dialog-title">
            <Formik {...formikConfig}>
                {formik => (
                    <Form>
                        <DialogTitle id="login-dialog-title">Anmelden</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Bitte geben Sie Ihre Anmeldedaten ein, die Sie über den Link in Ihrer Bergüßungsmail festgelegt haben.
                            </DialogContentText>
                            <OnSubmitValidationError error={errorMessage || "unbekannter Fehler"} />
                            <FieldsGroup>
                                <Field
                                    component={TextField}
                                    name="username"
                                    label="Benutzername / Kunden-Nr."
                                    fullWidth
                                    autoFocus
                                    autoComplete="username"
                                    autoCapitalize="off"
                                    autoCorrect="off"
                                />
                                <Field
                                    component={TextField}
                                    name="password"
                                    label="Kennwort"
                                    type="password"
                                    fullWidth
                                    autoComplete="current-password"
                                    autoCapitalize="off"
                                    autoCorrect="off"
                                />
                                <Alert severity="info">
                                    Mittels Klick auf <strong>Anmelden</strong> stimmen Sie der Nutzung eines funktionalen Cookies zu.
                                </Alert>
                            </FieldsGroup>
                            <DialogContentText>
                                Wenn Sie ihr Kennwort vergessen haben, klicken Sie bitte <Link onClick={props.onForgotPasswordClick}>diesen</Link> Link.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={props.handleClose}>
                                Abbrechen
                            </Button>
                            <Button type="submit" color="primary" disabled={!(formik.isValid && formik.dirty)}>
                                Anmelden
                            </Button>
                        </DialogActions>
                    </Form>
                )}
            </Formik>
        </Dialog>
    );
}

export default LoginDialog;