import { makeAutoObservable, reaction } from "mobx";

export default class SearchHandler<T> {

    minSearchLength: number;

    private readonly searchAction: (search: string) => Promise<Array<T>>;
    private readonly abortAction?: () => void;

    private _lastSearch = "";
    private _results = new Array<T>();
    private _isLoading = false;

    get lastSearch() {

        return this._lastSearch;
    }

    get results() {

        return this._results;
    }

    get isLoading() {

        return this._isLoading;
    }

    constructor(minSearchLength: number, delayMs: number, searchAction: (search: string) => Promise<Array<T>>, abortAction?: () => void) {

        this.minSearchLength = minSearchLength;
        this.searchAction = searchAction;
        this.abortAction = abortAction;

        makeAutoObservable(this, undefined, { deep: false, autoBind: true });

        reaction(
            () => this._lastSearch,
            search => this.triggerSearch(search),
            { delay: delayMs }
        );
    }

    search(searchString: string) {

        this._lastSearch = searchString;
    }

    abort() {

        this.abortAction?.();
        this._isLoading = false;
    }

    clear() {

        this._lastSearch = "";
        this.abortAction?.();
        this._results = [];
    }

    private async triggerSearch(searchString: string) {

        this.abortAction?.();

        if (searchString.length >= this.minSearchLength) {

            try {
                this._isLoading = true;
                this._results = await this.searchAction(searchString);
    
            } catch (error) {
                this._results = [];
                
            } finally {
                this._isLoading = false;
            }

        } else {
            this._isLoading = false;
            this._results = [];
        }
    }
}