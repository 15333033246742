import { makeAutoObservable } from "mobx";
import type IClosable from "./IClosable";

export default class ConfirmActionState implements IClosable {

    private otherStatesToClose = new Array<IClosable>();

    private confirmAction: () => void;

    private _isOpen = false;

    get isOpen() {
        return this._isOpen;
    }

    constructor(confirmAction: () => void) {

        this.confirmAction = confirmAction;

        makeAutoObservable(this, undefined, { autoBind: true });
    }

    open() {
        this.otherStatesToClose.forEach(s => s.close());
        this._isOpen = true;
    }

    close() {
        this._isOpen = false;
    }

    confirm() {

        this._isOpen = false;
        this.confirmAction();
    }

    closesOther(otherState: IClosable) {

        this.otherStatesToClose.push(otherState);
    }
}