import { Inject, Service } from "typedi";
import { makeAutoObservable } from "mobx";
import React from "react";
import DoneIcon from "@material-ui/icons/Done";
import EditIcon from "@material-ui/icons/Edit";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import GetAppIcon from "@material-ui/icons/GetApp";
import IBrowseFileInfo from "@shared/interfaces/IBrowseFileInfo";
import joinBrowsePath from "@shared/utils/joinBrowsePath";
import { ContactRead } from "@shared/requests/ContactRead";
import { ContactPut } from "@shared/requests/ContactPut";
import IContact from "@shared/interfaces/IContact";
import BrowseFileService from "../../services/BrowseFileService";
import FileNotificationService from "../../services/FileNotificationService";
import BrowseService from "../../services/BrowseService";
import UserService from "../../services/UserService";
import NotificationService, { NotificationType } from "../../services/NotificationService";
import EditDirectoryService from "../../services/EditDirectoryService";
import dateToReadable from "../../utils/dateToReadable";
import { IContextMenuAction } from "../../components/ContextMenu";
import { FileCategory } from "../../models/FileCategory";
import { FileDisplayMode } from "../../models/FileDisplayMode";
import ContextualConfirmActionState from "../../models/ContextualConfirmActionState";
import { contactPutToken, contactReadToken } from "../../requests";

@Service()
export class BrowseFilesListViewModel {

    private readonly browseContentService: BrowseFileService;
    private readonly fileNotificationService: FileNotificationService;
    private readonly browseService: BrowseService;
    private readonly userService: UserService;
    private readonly browseFileService: BrowseFileService;
    private readonly notificationService: NotificationService;
    private readonly editDirectoryService: EditDirectoryService;

    private readonly contactReadRequest: ContactRead;
    private readonly contactPutRequest: ContactPut;

    private _openedFile: IBrowseFileInfo | null = null;

    private _editingContact: { file: IBrowseFileInfo, data: IContact } | null = null;

    readonly fileToDelete = new ContextualConfirmActionState<IBrowseFileInfo>(file => this.deleteFile(file));

    get openedFile() {

        return this._openedFile;
    }

    get editingContact() {

        return this._editingContact;
    }

    get canEditFiles() {

        return this.userService.getIsBackOfficeUser();
    }

    constructor(
        @Inject() browseContentService: BrowseFileService,
        @Inject() fileNotificationService: FileNotificationService,
        @Inject() browseService: BrowseService,
        @Inject() userService: UserService,
        @Inject() browseFileService: BrowseFileService,
        @Inject() notificationService: NotificationService,
        @Inject() editDirectoryService: EditDirectoryService,
        @Inject(contactReadToken) contactReadRequest: ContactRead,
        @Inject(contactPutToken) contactPutRequest: ContactPut
    ) {
        this.browseContentService = browseContentService;
        this.fileNotificationService = fileNotificationService;
        this.browseService = browseService;
        this.userService = userService;
        this.browseFileService = browseFileService;
        this.notificationService = notificationService;
        this.editDirectoryService = editDirectoryService;
        this.contactReadRequest = contactReadRequest;
        this.contactPutRequest = contactPutRequest;

        makeAutoObservable(this, undefined, { deep: false, autoBind: true });
    }

    getFileLabel(file: IBrowseFileInfo) {

        return this.browseContentService.getTypeName(file.type);
    }

    getCategoryLabel(category: FileCategory) {

        return this.browseContentService.getCategoryLabel(category);
    }

    getFileCategory(file: IBrowseFileInfo) {

        return this.browseContentService.getCategory(file.type);
    }

    getDisplayMode(file: IBrowseFileInfo) {

        return this.browseContentService.getDisplayMode(file.type);
    }

    isFileNew(file: IBrowseFileInfo) {

        return this.fileNotificationService.hasNotification(this.getFilePath(file));
    }

    getTitleAndDetails(file: IBrowseFileInfo, category: FileCategory): [title: string, detail: string] {

        if (category === FileCategory.contact) {

            const titleSplit = file.title?.split(", ") || [];

            if (titleSplit.length > 1) {
             
                return [
                    titleSplit[0],
                    titleSplit[1]
                ];
            }
        }

        return [
            file.title || file.fileName,
            dateToReadable(file.lastModified)
        ];
    }

    getContextMenuActions(file: IBrowseFileInfo, isNewFile: boolean) {

        const actions = new Array<IContextMenuAction>();

        if (isNewFile) {
            actions.push({
                text: "Als gelesen markieren",
                icon: React.createElement(DoneIcon),
                onClick: () => this.fileNotificationService.read(this.getFilePath(file))
            });
        }

        if (this.userService.getIsBackOfficeUser()) {

            if (this.isContactFile(file)) {
                actions.push({
                    text: "Kontakt bearbeiten",
                    onClick: () => this.editContactFile(file),
                    icon: React.createElement(EditIcon)
                });
            }

            actions.push({
                text: "Löschen",
                onClick: () => this.fileToDelete.open(file),
                icon: React.createElement(DeleteForeverIcon)
            });
        }

        actions.push({
            text: "Lokal speichern",
            onClick: () => this.downloadFile(file),
            icon: React.createElement(GetAppIcon)
        });

        return actions;
    }

    openFile(fileOrName: IBrowseFileInfo | string) {

        const file = (typeof fileOrName === "string") ?
            this.browseService.currentDir?.contents.find(c => c.fileName === fileOrName) :
            fileOrName;
        
        if (file) {

            this.fileNotificationService.read(this.getFilePath(file));
            
            const displayMode = this.browseFileService.getDisplayMode(file.type);
            this._openedFile = this.canOpenFile(displayMode) ? file : null;
        }
    }

    closeFile() {

        this._openedFile = null;
    }

    showCopiedToClipboardMessage(text: string) {

        this.notificationService.show(`Text "${text}" kopiert`);
    }

    async editContactFile(file: IBrowseFileInfo) {

        if (this.isContactFile(file)) {

            const response = await this.contactReadRequest.send({}, null, { path: this.getFilePath(file) });
            if (response.success) {
    
                this._editingContact = {
                    file,
                    data: response.result
                };
            }
        }
    }

    async onEditContactSubmit(contact: IContact) {

        if (this._editingContact) {
         
            const file = this._editingContact.file;
            const response = await this.contactPutRequest.send({}, contact, { path: this.getFilePath(file) });

            if (response.success) {
                this.notificationService.show("Kontakt aktualisiert");
                this._editingContact = null;
                await this.browseService.reload();
            } else {
                this.notificationService.show(response.error, NotificationType.error);
            }
        }

        return null;
    }

    cancelEditContact() {

        this._editingContact = null;
    }

    private getFilePath(file: IBrowseFileInfo) {

        return joinBrowsePath(file.dirPath, file.fileName);
    }

    private canOpenFile(fileDisplayMode: FileDisplayMode) {

        return [
            FileDisplayMode.frameContent,
            FileDisplayMode.image,
            FileDisplayMode.video,
            FileDisplayMode.markdown,
            FileDisplayMode.contactPopup,
        ].includes(fileDisplayMode);
    }

    private async deleteFile(fileInfo: IBrowseFileInfo) {

        const filePath = this.getFilePath(fileInfo);

        const success = await this.editDirectoryService.deleteFile(filePath);
        if (success) {

            this.notificationService.show(`Datei "${fileInfo.fileName}" wurde gelöscht`);
            await this.browseService.reload();

        } else {
            this.notificationService.show(`Fehler beim Löschen der Datei ${fileInfo.fileName}`, NotificationType.error);
        }

        return success;
    }

    private isContactFile(file: IBrowseFileInfo) {

        return file.type === "vcf";
    }

    private downloadFile(file: IBrowseFileInfo) {

        const a = document.createElement("a");
        a.style.display = "none";
        a.href = file.url;
        a.setAttribute("download", file.fileName);
        
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }
}