export const clearName = /^(?:[\u0020-\u007e\u00c0-\u00ff]+\s?)*$/;
export const emailLikeName = /^(?:[@\'\.\-\w\u00c0-\u00ff]+\s?)*$/;
export const technicalSlug = /^[\w@_\-$&#\[\]]+$/;
export const customerNumber = /^\d{1,9}$/;
export const url = /^https?:\/\//;
export const fileExtension = /\.[A-z0-9]+$/;
export const fileNameAndExtension = /^(.+)\.([A-z0-9]+)$/;
export const validFileName = /^[\s\,\.\-\w&%$@!*\[\]\{\}\(\)+=~'\u00c0-\u00ff]+$/;
export const phoneNumber = /^\+?[\d\s\-()]+$/;
export const zipCode = /^[\dA-Z\s-]+$/;
export const variables = /\$\{([^}]+)\}/g;