import { Inject, Service } from "typedi";
import IBrowseDirectoryInfo, { IBrowseStandardDirectoryInfo } from "@shared/interfaces/IBrowseDirectoryInfo";
import { DirectoryPatch } from "@shared/requests/DirectoryPatch";
import getParentBrowsePath from "@shared/utils/getParentBrowsePath";
import { DirectoryRestrictSettings } from "@shared/interfaces/DirectoryRestrictSettings";
import { DirectoryDelete } from "@shared/requests/DirectoryDelete";
import { fileExtension } from "@shared/utils/regex";
import { directoryDeleteToken, directoryPatchToken } from "../requests";
import moveByOffset from "../utils/moveByOffset";
import BrowseService from "./BrowseService";
import NotificationService, { NotificationType } from "./NotificationService";

@Service()
export default class EditDirectoryService {

    private readonly browseService: BrowseService;
    private readonly notificationService: NotificationService;

    private readonly directoryPatchRequest: DirectoryPatch;
    private readonly directoryDeleteRequest: DirectoryDelete;

    constructor(
        @Inject() browseService: BrowseService,
        @Inject() notificationService: NotificationService,
        @Inject(directoryPatchToken) directoryPatchRequest: DirectoryPatch,
        @Inject(directoryDeleteToken) directoryDeleteRequest: DirectoryDelete
    ) {
        this.browseService = browseService;
        this.notificationService = notificationService;

        this.directoryPatchRequest = directoryPatchRequest;
        this.directoryDeleteRequest = directoryDeleteRequest;
    }

    async changeOrderByOffset(dir: IBrowseDirectoryInfo, offset: number) {

        offset = Math.round(offset);

        const parentPath = getParentBrowsePath(dir.path);
        const parentDir = await this.browseService.fetchForPath(parentPath);

        if (!parentDir) {
            return false;
        }

        const childrenPaths = parentDir.children.map(c => this.getLastPathSegment(c.path));

        const newOrder = moveByOffset(childrenPaths, this.getLastPathSegment(dir.path), offset);

        const result = await this.directoryPatchRequest.send({}, {
            order: newOrder
        }, {
            path: parentDir.info.path
        });

        if (!result.success) {
            this.notificationService.show("Fehler beim Ändern der Reihenfolge", NotificationType.error);
        }

        return result.success;
    }

    async changeIcon(dir: IBrowseDirectoryInfo, icon: string | null) {

        const result = await this.directoryPatchRequest.send({}, {
            icon
        }, {
            path: dir.path
        });

        if (!result.success) {
            this.notificationService.show("Fehler beim Ändern des Icons", NotificationType.error);
        }

        return result.success;
    }

    async changeRestrict(dir: IBrowseDirectoryInfo, restrict: DirectoryRestrictSettings | null) {

        const result = await this.directoryPatchRequest.send({}, {
            restrict
        }, {
            path: dir.path
        });

        if (!result.success) {
            this.notificationService.show("Fehler beim Ändern der Zugriffsberechigung", NotificationType.error);
        }

        return result.success;
    }

    async changeAutoDelete(dir: IBrowseStandardDirectoryInfo, autoDeleteDays: number) {

        const result = await this.directoryPatchRequest.send({}, {
            options: {
                ...dir.options,
                autoDeleteDays
            }
        }, {
            path: dir.path
        });

        if (!result.success) {
            this.notificationService.show("Fehler beim Ändern der automatischen Lösch-Option", NotificationType.error);
        }

        return result.success;
    }

    async deleteFile(path: string) {

        if (fileExtension.test(path)) {

            const response = await this.directoryDeleteRequest.send({}, null, { path });

            return response.success;
        }

        return false;
    }

    private getLastPathSegment(path: string) {

        return path.split("/").pop() || "";
    }
}