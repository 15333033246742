import { makeAutoObservable } from "mobx";
import { Inject, Service } from "typedi";
import ISearchResult from "@shared/interfaces/ISearchResult";
import { Search } from "@shared/requests/Search";
import getParentBrowsePath from "@shared/utils/getParentBrowsePath";
import replaceLeading from "@shared/utils/replaceLeading";
import SearchHandler from "../../models/SearchHandler";
import { searchToken } from "../../requests";
import BrowseFileService from "../../services/BrowseFileService";
import BrowseService from "../../services/BrowseService";

@Service()
export default class SearchViewModel {

    private readonly searchRequest: Search;
    private readonly browseFileService: BrowseFileService;
    private readonly browseService: BrowseService;

    readonly searchHandler: SearchHandler<ISearchResult>;

    constructor(
        @Inject(searchToken) searchRequest: Search,
        @Inject() browseFileService: BrowseFileService,
        @Inject() browseService: BrowseService
    ) {
        this.searchRequest = searchRequest;
        this.browseFileService = browseFileService;
        this.browseService = browseService;

        this.searchHandler = new SearchHandler(2, 200, async (searchString: string) => {

            const response = await this.searchRequest.send({}, null, { searchString });

            return response.success ? response.result : [];
        });

        makeAutoObservable(this, undefined, { autoBind: true });
    }

    onResultSelect(result: ISearchResult) {

        if (result.type === "dir") {
            this.browseService.browseTo(result.path);

        } else {
            const dirPath = getParentBrowsePath(result.path);
            const fileName = replaceLeading(result.path.replace(dirPath, ""), "/", "");

            this.browseService.browseTo(dirPath, fileName);
        }
    }

    getFileCategory(fileType: string) {

        return this.browseFileService.getCategory(fileType);
    }

    dispose() {

        this.searchRequest.abort();
        this.searchHandler.clear();
    }
}