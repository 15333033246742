export default function groupBy<T, K extends string | number>(list: Array<T>, keyFn: (item: T) => K) {

    return list.reduce((groups, item) => {
        const groupKey = keyFn(item);
        const groupItems = (groups.get(groupKey) || []);
        groupItems.push(item);
        groups.set(groupKey, groupItems);

        return groups;

    }, new Map<K, Array<T>>());
}