import React, { ComponentProps, useState } from "react";
import styled from "styled-components";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import FileUpload from "../../components/FileUpload";

const BottomRightContainer = styled.div(props => ({
    position: "fixed",
    bottom: props.theme.spacing(3),
    right: props.theme.spacing(3),
    zIndex: 2
}));

const FileUploadMenuItem = React.forwardRef<HTMLLIElement, ComponentProps<typeof FileUpload>>((props, ref) => (
    <FileUpload {...props}>
        <MenuItem button ref={ref}>
            Datei hochladen
        </MenuItem>
    </FileUpload>
));

interface IAddKnobProps {
    handleUploadFile: (formData: FormData) => void;
    onAddContactClick: () => void;
    onAddNewsClick?: () => void;
    children?: never;
}

const AddKnob: React.FC<IAddKnobProps> = props => {

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const onAddClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
    const onClose = () => setAnchorEl(null);

    const onUploadFile = (formData: FormData) => {
        props.handleUploadFile(formData);
        setAnchorEl(null);
    };

    const onAddContactClick = () => {
        props.onAddContactClick();
        setAnchorEl(null);
    };

    const onAddNewsClick = () => {
        props.onAddNewsClick?.();
        setAnchorEl(null);  
    };

    return (
        <BottomRightContainer>
            <Fab size="medium" onClick={onAddClick}>
                <AddIcon />
            </Fab>
            <Menu
                open={!!anchorEl}
                onClose={onClose}
                anchorEl={anchorEl}
            >
                <FileUploadMenuItem onUpload={onUploadFile}>
                    Datei hochladen
                </FileUploadMenuItem>
                <MenuItem button onClick={onAddContactClick}>
                    Neuer Kontakt
                </MenuItem>
                {!!props.onAddNewsClick && (
                    <MenuItem button onClick={onAddNewsClick}>
                        Neue wichtige Information
                    </MenuItem>
                )}
            </Menu>
        </BottomRightContainer>
    );
}

export default AddKnob;