import React, { useState } from "react";

const UnsafeSourceImg: React.FC<React.ImgHTMLAttributes<HTMLImageElement>> = props => {

    const [failed, setFailed] = useState(false);

    const onError = (event: React.SyntheticEvent<HTMLImageElement>) => {
        setFailed(true);
        props.onError?.(event);
    };

    return (
        <img {...props} style={failed ? { display: "none" } : props.style} onError={onError} />
    );
}

export default UnsafeSourceImg;