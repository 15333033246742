export default function moveByOffset<T>(source: Array<T>, item: T, offset: number) {

    const from = source.indexOf(item);
    const to = Math.max(from + offset, 0);

    const result = source.slice();

    let numberOfDeletedElm = 1;

    const elm = result.splice(from, numberOfDeletedElm)[0];

    numberOfDeletedElm = 0;

    result.splice(to, numberOfDeletedElm, elm);

    return result;
}