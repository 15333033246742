import React from "react"
import ReportProblemOutlined from "@material-ui/icons/ReportProblemOutlined";
import PageContainer from "../components/PageContainer";
import Sign from "../components/Sign";
import TextLink from "../components/TextLink";

const NotFound: React.FC = () => {

    return (
        <PageContainer>
            <Sign text="Hier ist nichts" icon={ReportProblemOutlined}>
                <p>Zur Startseite kehren Sie mit <TextLink to="/">diesem Link</TextLink> zurück</p>
            </Sign>
        </PageContainer>
    );
}

export default NotFound;