import styled from "styled-components";

const NotificationsContainer = styled.div(props => ({
    display: "flex",
    maxHeight: "100%",
    maxWidth: "100%",
    position: "fixed",
    flexDirection: "column",
    zIndex: props.theme.zIndex.snackbar,
    height: "auto",
    width: "auto",
    minWidth: 288,
    transition: props.theme.transitions.create(["bottom", "left"], { easing: "ease" }),
    bottom: props.theme.spacing(2),
    left: props.theme.spacing(2),
    [props.theme.breakpoints.down("xs")]: {
        left: 0,
        right: 0,
        width: "100%"
    },
    "& > div > *": { // the first div is the transition group
        marginTop: props.theme.spacing(2)
    },
    ":empty": {
        display: "none"
    }
}));

export default NotificationsContainer;