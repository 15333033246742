import { makeAutoObservable } from "mobx";
import { Inject, Service } from "typedi";
import type { History } from "history";
import IBrowseDirectory from "@shared/interfaces/IBrowseDirectory";
import IBrowseDirectoryInfo, { IBrowseCollectDirectoryInfo, IBrowseEmbedDirectoryInfo, IBrowseFormsDirectoryInfo } from "@shared/interfaces/IBrowseDirectoryInfo";
import { Browse } from "@shared/requests/Browse";
import sanitizePath from "@shared/utils/sanitizePath";
import joinBrowsePath from "@shared/utils/joinBrowsePath";
import { ResponseType } from "@shared/requests/RequestDefinition";
import { BrowseDirInfo } from "@shared/requests/BrowseDirInfo";
import { browseDirInfoToken, browseToken } from "../requests";
import { paths } from "../routes";
import { mediaMobile } from "../theme";
import { getApiUrl } from "../utils/createApiRequest";
import browserHistoryToken from "./browserHistoryToken";

@Service()
export default class BrowseService {

    private readonly browseRequest: Browse;
    private readonly browseDirInfoRequest: BrowseDirInfo;
    private runningRequest: Promise<ResponseType<{ directory: IBrowseDirectory | null }>> | null = null;
    private runningRequestPath: string | null = null;

    private _currentDir: IBrowseDirectory | null = null;

    private _isLoading = false;

    private readonly history: History;

    get currentDir() {
        return this._currentDir;
    }

    get isLoading() {
        return this._isLoading;
    }

    constructor(
        @Inject(browseToken) browseRequest: Browse,
        @Inject(browseDirInfoToken) browseDirInfoRequest: BrowseDirInfo,
        @Inject(browserHistoryToken) history: History
    ) {
        this.browseRequest = browseRequest;
        this.browseDirInfoRequest = browseDirInfoRequest;
        this.history = history;

        makeAutoObservable(this, undefined, { deep: false });
    }

    async setPath(path: string, forceReload = false) {

        path = sanitizePath(path);

        if (path === this._currentDir?.info.path && !forceReload) {
            return true;
        }

        this._isLoading = true;

        const response = await this.sendDistinctBrowseRequest(path);

        this._isLoading = false;

        if (!response.success) {
            console.error(`Error browsing path \"${path}\" Status: ${response.status}`);
        } else {
            this._currentDir = response.result.directory;

            return true;
        }

        return false;
    }

    async browseTo(infoOrPath: IBrowseDirectoryInfo | string, openFile?: string) {

        let info: IBrowseDirectoryInfo;
        
        if (typeof infoOrPath === "string") {

            const response = await this.browseDirInfoRequest.send({}, null, { path: infoOrPath });
            if (!response.success) {
                return;
            }

            info = response.result;

        } else {
            info = infoOrPath;
        }

        if (this._currentDir?.info.path === info.path) {
            return;
        }

        if (this.isEmbedDir(info)) {

            const embedInfo = info as IBrowseEmbedDirectoryInfo;

            if (this.shouldOpenInSameWindow(embedInfo)) {
                window.location.href = embedInfo.options.url;
                
            } else if (this.shouldOpenInNewTab(embedInfo)) {
                window.open(embedInfo.options.url, "_blank")?.focus();

            } else {
                const queryString = new URLSearchParams(embedInfo.options as any).toString();
                await this.setPath(info.path);

                this.history.push("/embed" + embedInfo.path + "?" + queryString);
            }

            return;
        }

        if (this.isCollectDir(info)) {

            await this.setPath(info.path)
            this.history.push("/collect" + info.path);

            return;
        }

        if (this.isFormsDir(info)) {

            await this.setPath(info.path)
            this.history.push("/forms" + info.path);

            return;
        }

        if (await this.setPath(info.path)) {

            const location = info.path === "/" ?
                "/" :
                "/browse" + this.currentDir?.info.path;

            const search = openFile ? ("?" + new URLSearchParams({ open: openFile }).toString()) : "";

            this.history.push(location + search);

        } else {
            this.history.replace(paths.notFound.get());
        }
    }

    isEmbedDir(info: IBrowseDirectoryInfo): info is IBrowseEmbedDirectoryInfo {

        return "type" in info && info.type === "embed";
    }

    isCollectDir(info: IBrowseDirectoryInfo): info is IBrowseCollectDirectoryInfo {

        return "type" in info && info.type === "collect";
    }

    isFormsDir(info: IBrowseDirectoryInfo): info is IBrowseFormsDirectoryInfo {

        return "type" in info && info.type === "forms";
    }

    async fetchForPath(path: string) {

        path = sanitizePath(path);

        const response = await this.sendDistinctBrowseRequest(path);

        return response.success && response.result.directory || null;
    }

    getDirectoryUrl(info: IBrowseDirectoryInfo) {

        return "/browse" + info.path;
    }

    getDirectoryDownloadUrl(info: IBrowseDirectoryInfo) {

        return getApiUrl(joinBrowsePath("download", info.path), null);
    }

    reload() {

        const currentPath = this._currentDir?.info.path || "/";

        return this.setPath(currentPath, true);
    }

    private sendDistinctBrowseRequest(path: string) {

        if (this.runningRequest && this.runningRequestPath === path) {
            return this.runningRequest;
        }

        const newRequest = this.browseRequest.send({}, null, { path });
        this.runningRequest = newRequest;
        this.runningRequestPath = path;

        return newRequest.finally(() => {
            this.runningRequest = null;
            this.runningRequestPath = null;
        });
    }

    private shouldOpenInNewTab(info: IBrowseEmbedDirectoryInfo) {

        if (info.options.targetMobile && matchMedia(mediaMobile).matches) {
            
            return info.options.targetMobile === "blank";
        }

        return info.options.target === "blank";
    }

    private shouldOpenInSameWindow(info: IBrowseEmbedDirectoryInfo) {
        
        if (info.options.targetMobile && matchMedia(mediaMobile).matches) {
            
            return info.options.targetMobile === "top";
        }

        return info.options.target === "top";
    }
}