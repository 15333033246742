import { rgb } from "polished";
import { createGlobalStyle } from "styled-components";
import SpartanMBRegularWoff2 from "./fonts/spartanmb-regular.woff2";
import SpartanMBRegularWoff from "./fonts/spartanmb-regular.woff";
import SpartanMBBoldWoff2 from "./fonts/spartanmb-bold.woff2";
import SpartanMBBoldWoff from "./fonts/spartanmb-bold.woff";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";

const unicodeRanges = {
    basicLatin: "U+0020-007F",
    latin1Supplement: "U+00A0-00FF", // german umlauts
    currency: "U+20A0-20CF"
};

const unicodeRange = [unicodeRanges.basicLatin, unicodeRanges.latin1Supplement, unicodeRanges.currency].join(", ");

export const fontName = "Spartan MB";

export const GlobalFontFaces = createGlobalStyle`
    @font-face {
        font-family: '${fontName}';
        src: local('Spartan MB Regular'), local('Spartan MB'), url('${SpartanMBRegularWoff2}') format('woff2'), url('${SpartanMBRegularWoff}') format('woff');
        unicode-range: ${unicodeRange};
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: '${fontName}';
        src: local('Spartan MB Bold'), local('Spartan MB'), url('${SpartanMBBoldWoff2}') format('woff2'), url('${SpartanMBBoldWoff}') format('woff');
        unicode-range: ${unicodeRange};
        font-weight: 700;
        font-style: normal;
    }
`;

export const textWhite = rgb(255, 255, 255);
export const brandColor = rgb(231, 49, 56);
export const lightGrey = rgb(157, 157, 156);
export const darkerGrey = rgb(92, 94, 95);
export const darkGrey = rgb(112, 111, 111);
export const warnAndError = rgb(255, 36, 0);
export const fontFamily = `'${fontName}', Open Sans, Arial, sans-serif`;

export const mediaMobile = "(max-width:600px)";

const theme = createMuiTheme({
    palette: {
        primary: {
            main: brandColor
        },
        secondary: {
            main: darkerGrey
        },
        error: {
            main: warnAndError
        },
        warning: {
            main: warnAndError
        }
    },
    typography: {
        fontFamily,
        h1: {
            fontSize: "2.5rem"
        },
        h2: {
            fontSize: "2rem"
        },
        h3: {
            fontSize: "1.75rem"
        },
        h4: {
            fontSize: "1.5rem"
        },
        h5: {
            fontSize: "1.375rem"
        },
        h6: {
            fontSize: "1.25rem"
        }
    }
});

export default theme;