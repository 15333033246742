import { makeAutoObservable } from "mobx";
import { Inject, Service } from "typedi";
import type { History } from "history";
import { AuthChangePassword } from "@shared/requests/AuthChangePassword";
import { AuthForgotPassword, AuthForgotPasswordBody } from "@shared/requests/AuthForgotPassword";
import replaceLeading from "@shared/utils/replaceLeading";
import joinBrowsePath from "@shared/utils/joinBrowsePath";
import IBrowseDirectoryInfo from "@shared/interfaces/IBrowseDirectoryInfo";
import waitMs from "@shared/utils/waitMs";
import IAnnouncementResponseItem from "@shared/interfaces/IAnnouncementResponseItem";
import UserService from "../../services/UserService";
import browserHistoryToken from "../../services/browserHistoryToken";
import NotificationService, { NotificationType } from "../../services/NotificationService";
import FileNotificationService from "../../services/FileNotificationService";
import ConfirmActionState from "../../models/ConfirmActionState";
import OpenCloseState from "../../models/OpenCloseState";
import { authChangePasswordToken, authForgotPasswordToken } from "../../requests";
import { paths } from "../../routes";
import BrowseService from "../../services/BrowseService";
import startDirectoryToken from "../../services/startDirectoryToken";
import AnnouncementsService from "../../services/AnnouncementsService";

@Service()
export default class LayoutViewModel {

    private readonly startDirectory: string;

    private readonly history: History;

    private readonly authForgotPassword: AuthForgotPassword;
    private readonly authChangePassword: AuthChangePassword;

    private readonly userService: UserService;
    private readonly notificationService: NotificationService;
    private readonly fileNotificationService: FileNotificationService
    private readonly browseService: BrowseService;
    private readonly announcementsService: AnnouncementsService;

    private _rootDirectories = new Array<IBrowseDirectoryInfo>();

    readonly loginDialog = new OpenCloseState();
    readonly backOfficeSideMenu = new OpenCloseState();
    readonly forgotPasswordDialog = new OpenCloseState();
    readonly changePasswordDialog = new ConfirmActionState(() => this.changePassword());

    loginReturnPath = "/";

    isMainMenuOpen = false;

    get isLoginStatusKnown() {

        return this.userService.isInitialized;
    }

    get userInfo() {

        return this.userService.userInfo;
    }

    get notifications() {

        return this.notificationService.currentlyDisplayed;
    }

    get fileNotifications() {

        return this.fileNotificationService.notifications;
    }

    get rooDirectories() {

        return this._rootDirectories;
    }

    get currentBrowsePath() {

        return this.browseService.currentDir?.info.path || "/";
    }

    get activeAnnouncements() {

        return this.announcementsService.activeAnnouncements;
    }

    get unreadAnnouncements() {

        return this.announcementsService.unreadAnnouncements;
    }

    get canDismissAllNotifications() {

        return this.userService.getIsBackOfficeUser();
    }

    get canViewBackOfficeMenu() {

        return this.userService.getIsBackOfficeUser();
    }

    constructor(
        @Inject(startDirectoryToken) startDirectory: string,
        @Inject(browserHistoryToken) history: History,
        @Inject() userService: UserService,
        @Inject() notificationService: NotificationService,
        @Inject() fileNotificationService: FileNotificationService,
        @Inject() browseService: BrowseService,
        @Inject() announcementsService: AnnouncementsService,
        @Inject(authForgotPasswordToken) authForgotPassword: AuthForgotPassword,
        @Inject(authChangePasswordToken) authChangePassword: AuthChangePassword,
    ) {
        this.startDirectory = startDirectory;

        this.history = history;
        this.userService = userService;
        this.notificationService = notificationService;
        this.fileNotificationService = fileNotificationService;
        this.browseService = browseService;
        this.announcementsService = announcementsService;
        
        this.authForgotPassword = authForgotPassword;
        this.authChangePassword = authChangePassword;

        this.backOfficeSideMenu.closesOther(this.loginDialog);

        this.loginDialog.closesOther(this.forgotPasswordDialog);
        this.loginDialog.closesOther(this.changePasswordDialog);

        this.forgotPasswordDialog.closesOther(this.loginDialog);
        this.forgotPasswordDialog.closesOther(this.changePasswordDialog);

        this.changePasswordDialog.closesOther(this.loginDialog);
        this.changePasswordDialog.closesOther(this.forgotPasswordDialog);

        makeAutoObservable(this, undefined, { autoBind: true, deep: false });
    }

    async init() {

        if (this.userInfo) {

            const rootDirectory = await this.browseService.fetchForPath("/");

            this._rootDirectories = rootDirectory?.children
                .filter(c => c.path !== this.startDirectory) ||
                [];
        }
    }

    startPageLinkClick() {

        this.goToStartPage();
    }

    async login(username: string, password: string) {
    
        const response = await this.userService.login(username.trim(), password.trim());

        if (response.success) {

            this.notificationService.show("Sie sind angemeldet", NotificationType.success);

            this.history.push(this.loginReturnPath);
            this.loginReturnPath = "/";
        }

        return response;
    }

    async logout() {

        this.backOfficeSideMenu.close();

        await this.userService.logout();

        this.notificationService.show("Sie wurden abgemeldet");

        this.goToStartPage();
    }

    async changePassword() { 

        const response = await this.authChangePassword.send({}, null, {});

        if (response.success && response.result.mailSent) {
            
            this.notificationService.show("E-Mail wurde versendet");

            return true;
        }

        return false;
    }

    async sendForgottenPasswordMail(body: AuthForgotPasswordBody) {

        body.username = body.username.trim();
        body.email = body.email.trim();

        const response = await this.authForgotPassword.send({}, body, {});

        if (response.success && response.result.mailSent) {
            
            this.notificationService.show("E-Mail zur Wiederherstellung wurde versendet");

            return true;
        }

        return false;
    }

    goToStartPage() {

        this.history.push(paths.startPage.get());
    }

    closeNotification(id: string) {

        this.notificationService.hide(id);
    }

    browseTo(browsePath: string) {

        const path = paths.browse.get({ "0": replaceLeading(browsePath, "/", "") });
        this.history.push(path);
    }

    showAnnouncement(announcement: IAnnouncementResponseItem) {

        this.announcementsService.unread(announcement);
    }

    async onMainMenuItemClick(item: IBrowseDirectoryInfo) {

        this.browseService.browseTo(item);

        await waitMs(600);

        this.isMainMenuOpen = false;
    }

    toggleMainMenu() {

        this.isMainMenuOpen = !this.isMainMenuOpen;
    }

    dismissAnnouncement(announcement: IAnnouncementResponseItem) {

        this.announcementsService.dismiss(announcement);
    }

    readAllFileNotifications() {

        return this.fileNotificationService.readAll();
    }

    readFileNotificationsInDir(dirPath: string) {

        return this.fileNotificationService.read(dirPath);
    }
    
    readFileNotification(dirPath: string, file: string) {

        return this.fileNotificationService.read(joinBrowsePath(dirPath, file));
    }
}