import { makeAutoObservable, observable, reaction } from "mobx";
import { DirectorySearch } from "@shared/requests/DirectorySearch";
import replaceLeading from "@shared/utils/replaceLeading";
import IPathAutocompleteState from "./IPathAutocompleteState";

interface IPathAutocompleteStateOptions {
    fileExtensions?: Array<string>;
    hiddenDirectories?: boolean;
}

export default class PathAutocompleteState implements IPathAutocompleteState {

    private readonly options: IPathAutocompleteStateOptions;

    private readonly directorySearchRequest: DirectorySearch;

    private _path = "";

    private _suggestionsCache = observable.map<string, Array<string>>();

    get path () {

        return this._path;
    }

    get suggestions () {

        return this._suggestionsCache.get(this._path) || [];
    }

    constructor(
        directorySearchRequest: DirectorySearch,
        options: IPathAutocompleteStateOptions = {}
    ) {
        this.options = options;

        this.directorySearchRequest = directorySearchRequest;

        makeAutoObservable(this, undefined, { autoBind: true });

        reaction(
            () => this._path,
            async path => this.loadSuggestions(path),
            { delay: 200 }
        );
    }

    async setPath(path: string) {

        this._path = replaceLeading(path, "/", "/");
    }

    private async loadSuggestions(path: string) {

        if (path && !this._suggestionsCache.has(path)) {

            this.directorySearchRequest.abort();
            const searchResult = await this.directorySearchRequest.send({}, null, {
                path,
                fileExtensions: this.options.fileExtensions,
                hiddenDirectories: this.options.hiddenDirectories
            });

            this._suggestionsCache.set(path, searchResult.success && searchResult.result.children || []);
        }
    }
}