import "reflect-metadata";
import React from "react";
import { render } from "react-dom";
import { ThemeProvider } from "styled-components";
import Container from "typedi";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { renderRoutes } from "react-router-config";
import { configure } from "mobx";
import * as Yup from "yup";
import { de } from "yup-locales";
import "./requests";
import { GlobalNormalize, GlobalHtmlStyle } from "./style";
import UserService from "./services/UserService";
import theme, { GlobalFontFaces } from "./theme";
import routes from "./routes";
import browserHistoryToken from "./services/browserHistoryToken";
import startDirectoryToken from "./services/startDirectoryToken";
import { MuiThemeProvider, StylesProvider } from "@material-ui/core/styles";
import FileNotificationService from "./services/FileNotificationService";
import LayoutViewModel from "./pages/layout/LayoutViewModel";
import { emailMessage, maxLength, minLength, requiredMessage } from "./utils/validationMessages";
import AnnouncementsService from "./services/AnnouncementsService";

Yup.setLocale({
    ...de,
    mixed: {
        required: requiredMessage,
        notType: (args: any) => (args.value === null) ? requiredMessage : "Der Wert ist vom falschen Typ"
    },
    string: {
        min: minLength,
        max: maxLength,
        email: emailMessage,

    },
    number: {
        min: minLength,
        max: maxLength
    }
});

configure({ enforceActions: "never" });

const browserHistory = createBrowserHistory();
Container.set(browserHistoryToken, browserHistory);
Container.set(startDirectoryToken, "/Start");

const userService = Container.get(UserService);
const layoutViewModel = Container.get(LayoutViewModel);
const fileNotificationService = Container.get(FileNotificationService);
const announcementsService = Container.get(AnnouncementsService);

browserHistory.listen(() => layoutViewModel.init());
userService.init().then(() => layoutViewModel.init()); // TODO: this needs performance optimization
fileNotificationService.init();
announcementsService.init();

render(
    (
        // <React.StrictMode> not supported by MUI
        <>
            <StylesProvider injectFirst>
                <GlobalFontFaces />
                <GlobalNormalize />
                <GlobalHtmlStyle />
                <MuiThemeProvider theme={theme}>
                    <ThemeProvider theme={theme}>
                        <Router history={browserHistory}>
                            {renderRoutes(routes)}
                        </Router>
                    </ThemeProvider>
                </MuiThemeProvider>
            </StylesProvider>
        </>
        // </React.StrictMode>
    ),
    document.getElementById("root")
);