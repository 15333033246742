export enum UserRole {
    manageDirectoryAccess = "role.manageDirectoryAccess",
    manageWebDAV = "role.manageWebDAV",
    setExternalReference = "role.setExternalReference",
    manageUsers = "role.manageUsers",
    manageDirectoryAppearance = "role.manageDirectoryAppearance",
    manageAnnouncements = "role.manageAnnouncements",
    manageDirectoryOptions = "role.manageDirectoryOptions"
}

export const allUserRoles = [
    UserRole.manageDirectoryAccess,
    UserRole.manageWebDAV,
    UserRole.setExternalReference,
    UserRole.manageUsers,
    UserRole.manageDirectoryAppearance,
    UserRole.manageAnnouncements,
    UserRole.manageDirectoryOptions
];

export function parseUserRole(from: string) {

    if (allUserRoles.includes(from as UserRole)) {
        return from as UserRole;
    }

    return null;
}

export function parseAllUserRoles(array: Array<string>) {

    return array.map(s => parseUserRole(s)).filter((i): i is UserRole => !!i); 
}

export const userRoleLabels: Record<UserRole, string> = {
    [UserRole.manageAnnouncements]: "Ankündigungen verwalten",
    [UserRole.manageDirectoryAccess]: "Zugriff verwalten",
    [UserRole.manageDirectoryAppearance]: "Verzeichnis anpassen",
    [UserRole.manageDirectoryOptions]: "Verzeichnis konfigurieren",
    [UserRole.manageUsers]: "Benutzer verwalten",
    [UserRole.manageWebDAV]: "Verzeichnisse erstellen",
    [UserRole.setExternalReference]: "Als Kunde maskieren"
};