export enum EditTileAction {
    oneStepLeft,
    oneStepRight,
    delete,
    icon,
    image,
    virtualEmbed,
    restrict,
    autoDelete,
    name,
    download
}