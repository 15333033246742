import React, { useRef } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import useHasChildNodes from "../utils/useHasChildNodes";

const slotPrefix = "slot_";
const noop = () => { };

const SlotDiv = styled.div`&:empty { display: none; }`;

interface ISlotProps {
    id: string;
    className?: string;
    children?: never;
    onHasChildNodes?: (hasChildNodes: boolean) => void;
}

const Slot: React.FC<ISlotProps> = props => {

    const ref = useRef<HTMLDivElement>(null);

    useHasChildNodes(ref, props.onHasChildNodes || noop, true);

    return (
        <SlotDiv id={slotPrefix + props.id} ref={ref} className={props.className} />
    );
};

export const Fill: React.FC<{ id: string }> = props => {

    const slotElement = document.getElementById(slotPrefix + props.id);

    return slotElement ? createPortal(props.children, slotElement) : null;
};

export default Slot;