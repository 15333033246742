import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { SvgIconProps } from "@material-ui/core/SvgIcon";

interface ITitleBadgeIconProps {
    icon: React.ComponentType<SvgIconProps>;
    tooltip: string;
    children?: never;
}

const TitleBadgeIcon: React.FC<ITitleBadgeIconProps> = props => (
    <Tooltip title={props.tooltip} placement="bottom">
        {React.createElement(props.icon, { color: "inherit" })}
    </Tooltip>
);

export default TitleBadgeIcon;