import { makeAutoObservable } from "mobx";
import { Inject, Service } from "typedi";
import type { History } from "history";
import IBrowseDirectoryInfo, { IBrowseCollectDirectoryInfo, IBrowseStandardDirectoryInfo } from "@shared/interfaces/IBrowseDirectoryInfo";
import IBrowseFileInfo from "@shared/interfaces/IBrowseFileInfo";
import { DirectoryRestrictSettings } from "@shared/interfaces/DirectoryRestrictSettings";
import INewsResponseItem from "@shared/interfaces/INewsResponseItem";
import { IDirectoryPostResponse } from "@shared/requests/DirectoryPost";
import { ResponseType } from "@shared/requests/RequestDefinition";
import IContact from "@shared/interfaces/IContact";
import { ContactPut } from "@shared/requests/ContactPut";
import { DirectorySearch } from "@shared/requests/DirectorySearch";
import { INewsCreateBody } from "@shared/requests/NewsCreate";
import { UserRole } from "@shared/models/UserRole";
import browserHistoryToken from "../../services/browserHistoryToken";
import BrowseService from "../../services/BrowseService";
import BrowseFileService from "../../services/BrowseFileService";
import FileNotificationService from "../../services/FileNotificationService";
import NewsService from "../../services/NewsService";
import startDirectoryToken from "../../services/startDirectoryToken";
import UserService from "../../services/UserService";
import EditDirectoryService from "../../services/EditDirectoryService";
import NotificationService, { NotificationType } from "../../services/NotificationService";
import { EditTileAction } from "../../models/EditTileAction";
import { paths } from "../../routes";
import OpenCloseState from "../../models/OpenCloseState";
import { directoryPostToken, contactPutToken, directorySearchToken } from "../../requests";
import groupBy from "../../utils/groupBy";
import { FileCategory } from "../../models/FileCategory";
import PathAutocompleteState from "../../models/PathAutocompleteState";

@Service()
export default class BrowseViewModel {

    private readonly startDirectory: string;

    private readonly browseService: BrowseService;
    private readonly browseFileService: BrowseFileService;
    private readonly browseContentService: BrowseFileService;
    private readonly notificationService: NotificationService;
    private readonly fileNotificationService: FileNotificationService;
    private readonly newsService: NewsService;
    private readonly userService: UserService;
    private readonly editDirectoryService: EditDirectoryService;

    private readonly history: History;

    private readonly directoryPost: (formData: FormData, path: string, notify: boolean) => Promise<ResponseType<IDirectoryPostResponse>>;
    private readonly contactPutRequest: ContactPut;

    private _editingDirectoryAndAction: [info: IBrowseDirectoryInfo, action: EditTileAction] | null = null;

    private _news = new Array<INewsResponseItem>();

    readonly addContactDialog = new OpenCloseState();

    readonly addNewsDialog = new OpenCloseState();

    readonly pathAutocompleteState: PathAutocompleteState;

    get editingDirectory() {
        return this._editingDirectoryAndAction?.[0] || null;
    }

    get editingAction() {
        return this._editingDirectoryAndAction?.[1] || null;
    }


    get currentDir() {
        return this.browseService.currentDir;
    }

    get showEditDialogs() {

        return this.userService.getIsBackOfficeUser();
    }

    get news(): Array<INewsResponseItem> {
        return this._news;
    }

    get isAtStartDir() {
        return this.currentDir?.info.path === this.startDirectory;
    }

    get userIsLoggedIn() {
        return this.userService.isLoggedIn;
    }

    get showDirInfos() {
        return this.userService.getIsBackOfficeUser();
    }

    get canEditTileAppearance() {
        return this.userService.getIsBackOfficeUser(UserRole.manageDirectoryAppearance);
    }

    get canSetAutoDelete() {
        return this.userService.getIsBackOfficeUser(UserRole.manageDirectoryOptions);
    }

    get canEditRestrict() {
        return this.userService.getIsBackOfficeUser(UserRole.manageDirectoryAccess);
    }

    get canEditAnnouncements() {
        return this.userService.getIsBackOfficeUser(UserRole.manageAnnouncements);
    }

    constructor(
        @Inject(startDirectoryToken) startDirectory: string,
        @Inject() browseService: BrowseService,
        @Inject() browseFileService: BrowseFileService,
        @Inject() browseContentService: BrowseFileService,
        @Inject(browserHistoryToken) history: History,
        @Inject() notificationService: NotificationService,
        @Inject() fileNotificationService: FileNotificationService,
        @Inject() newsService: NewsService,
        @Inject() userService: UserService,
        @Inject() editDirectoryService: EditDirectoryService,
        @Inject(directoryPostToken) directoryPost: (formData: FormData, path: string, notify: boolean) => Promise<ResponseType<IDirectoryPostResponse>>,
        @Inject(contactPutToken) contactPutRequest: ContactPut,
        @Inject(directorySearchToken) directorySearchRequest: DirectorySearch
    ) {
        this.startDirectory = startDirectory;
        this.browseService = browseService;
        this.browseFileService = browseFileService;
        this.browseContentService = browseContentService;
        this.history = history;
        this.notificationService = notificationService;
        this.fileNotificationService = fileNotificationService;
        this.newsService = newsService;
        this.userService = userService;
        this.editDirectoryService = editDirectoryService;
        this.directoryPost = directoryPost;
        this.contactPutRequest = contactPutRequest;
        this.pathAutocompleteState = new PathAutocompleteState(directorySearchRequest);

        makeAutoObservable(this, undefined, { deep: false, autoBind: true });
    }

    async initWithPath(path: string, openFile: string | null = null) {

        if (path === "/") {
            path = this.startDirectory;
        }

        let success = await this.browseService.setPath(path);
        if (!success) {

            if (path === this.startDirectory) {

                console.log(`Start directory "${path}" could not be found. Showing root directory instead.`);
                success = await this.browseService.setPath("/");
            }

            if (!success) {
                this.history.replace(paths.notFound.get());
            }
        }

        this.fetchNews();
    }

    onDirectoryClick(info: IBrowseDirectoryInfo) {

        this.browseService.browseTo(info);
    }

    getDisplayMode(file: IBrowseFileInfo) {

        return this.browseFileService.getDisplayMode(file.type);
    }

    getDirNotificationCount(info: IBrowseDirectoryInfo) {

        if (this.isCollectDir(info)) {

            return info.options.reduce((totalCount, [name, path]) => {
                return totalCount + this.fileNotificationService.countInDirectory(path).length;
            }, 0);
        }

        if (info.type === "directory") {
            return this.fileNotificationService.countInDirectory(info.path).length;
        }
        
        return 0;
    }

    getFilesInGroups(): Array<[string, Array<IBrowseFileInfo>]> {

        const directory = this.browseService.currentDir;
        if (!directory) {
            return [];
        }

        let fileByCategory = Array.from(groupBy(directory.contents, f => this.browseFileService.getCategory(f.type)));
        fileByCategory = fileByCategory.sort((a, b) => b[0] === FileCategory.contact ? 1 : -1);

        return fileByCategory.map(([category, files]) => [
            this.browseContentService.getCategoryLabel(category) || "Dateien",
            files
        ]);
    }

    async handleUploadFile(formData: FormData) {

        if (this.currentDir) {

            const response = await this.directoryPost(formData, this.currentDir.info.path, true);
            if (response.success) {
    
                this.notificationService.show(`Datei "${response.result.file}" wurde hochgeladen`);
                await this.browseService.reload();

            } else {
                this.notificationService.show("Datei konnte nicht hochgeladen werden", NotificationType.error);
            }
        }
    }

    async onEditDirectory(info: IBrowseDirectoryInfo, action: EditTileAction) {

        this._editingDirectoryAndAction = [info, action];
        let forceReload = true;

        if (action === EditTileAction.oneStepLeft) {
            forceReload = await this.editDirectoryService.changeOrderByOffset(info, -1);
            this._editingDirectoryAndAction = null;

        } else if (action === EditTileAction.oneStepRight) {
            forceReload = await this.editDirectoryService.changeOrderByOffset(info, 1);
            this._editingDirectoryAndAction = null;

        } else if (action === EditTileAction.download) {
            forceReload = false;
            this.downloadDirectory(info);
        }

        if (forceReload) {
            await this.browseService.reload();
        }
    }

    async onSelectIconConfirm(iconName: string | null) {

        if (this.editingDirectory) {

            const success = await this.editDirectoryService.changeIcon(this.editingDirectory, iconName);
            if (success) {
    
                this._editingDirectoryAndAction = null;
                await this.browseService.reload();
            }
        }
    }

    async onEditRestrictConfirm(restrict: DirectoryRestrictSettings | null) {

        if (this.editingDirectory) {

            const success = await this.editDirectoryService.changeRestrict(this.editingDirectory, restrict);
            if (success) {
    
                this._editingDirectoryAndAction = null;
                await this.browseService.reload();
            }
        }
    }

    async onEditAutoDeleteConfirm(autoDeleteDays: number) {

        if (this.editingDirectory && this.editingDirectory.type === "directory") {

            const success = await this.editDirectoryService.changeAutoDelete(
                this.editingDirectory as IBrowseStandardDirectoryInfo,
                autoDeleteDays
            );

            if (success) {
    
                this._editingDirectoryAndAction = null;
                await this.browseService.reload();
            }
        }
    }

    async onCreateContactSubmit(contact: IContact) {

        if (this.currentDir) {
            
            const response = await this.contactPutRequest.send({}, contact, { path: this.currentDir.info.path });

            if (response.success) {
                this.notificationService.show("Kontakt erstellt");
                await this.browseService.reload();
            } else {
                this.notificationService.show(response.error, NotificationType.error);
            }
        }

        return null;
    }

    cancelEditDirectory() {
        this._editingDirectoryAndAction = null;
    }

    async createNews(fields: INewsCreateBody) {

        const createdNews = await this.newsService.create(fields);

        if (createdNews) {
            this.notificationService.show("Information hinzugefügt");
            this.fetchNews();
        } else {
            this.notificationService.show("Fehler beim Hinzufügen der Information");
        }

        return null; // TODO: null | PropertyErrors
    }

    private async fetchNews() {

        let newsPath = this.browseService.currentDir?.info.path || "/";
        if (newsPath === this.startDirectory) {
            newsPath = "/";
        }

        this._news = await this.newsService.getForPath(newsPath);
    }

    private isCollectDir(info: IBrowseDirectoryInfo): info is IBrowseCollectDirectoryInfo {

        return ("type" in info) && info.type === "collect";
    }

    private downloadDirectory(info: IBrowseDirectoryInfo) {

        window.location.href = this.browseService.getDirectoryDownloadUrl(info);
    }
}