import React from "react";
import styled from "styled-components";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";

const OffPageMenuRoot = styled.div({
    display: "flex",
    position: "relative",
    height: "100%"
});

interface IOnPageContentProps {
    shifted: boolean;
    menuWidth: number;
}

const OnPageContent = styled.div<IOnPageContentProps>(props => ({
    flexGrow: 1,
    transition: props.theme.transitions.create(
        ["margin", "width"],
        props.shifted ? ({
            easing: props.theme.transitions.easing.easeOut,
            duration: props.theme.transitions.duration.enteringScreen
        }) : ({
            easing: props.theme.transitions.easing.sharp,
            duration: props.theme.transitions.duration.leavingScreen
        })
    ),
    width: props.shifted ? `calc(100% - ${props.menuWidth}px)` : undefined,
    marginLeft: props.shifted ? props.menuWidth : 0,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    maxWidth: "100%"
}));

interface IOffPageMenuProps {
    menuWidth: number;
    menuContent: React.ReactNode;
    open: boolean;
    onClose: () => void;
}

const OffPageMenu: React.FC<IOffPageMenuProps> = props => {

    return (
        <OffPageMenuRoot>
            <Drawer
                variant="persistent"
                anchor="left"
                // style={{ flexShrink: 0, width: props.menuWidth }}
                open={props.open}
                onClose={props.onClose}
                PaperProps={{ style: { width: props.menuWidth } }}
            >
                <List>
                    <ListItem button onClick={props.onClose}>
                        <ListItemIcon><ChevronLeftIcon /></ListItemIcon>
                        <ListItemText primary="schließen" />
                    </ListItem>
                </List>
                <Divider />
                {props.menuContent}
            </Drawer>
            <OnPageContent shifted={props.open} menuWidth={props.menuWidth}>
                {props.children}
            </OnPageContent>
        </OffPageMenuRoot>
    );
}

export default OffPageMenu;