export enum FileDisplayMode {
    /**
     * as a download link
     */
    download,
    /**
     * as a link to open in new tab
     */
    open,
    /**
     * show in iframe overlay
     */
    frameContent,
    /**
     * show in image-viewer overlay
     */
    image,
    /**
     * show in video-player overlay
     */
    video,
    /**
     * show in markdown renderer overlay
     */
    markdown,
    /**
     * Popup to show contact data from vCard file
     */
    contactPopup

    // TODO: handling .url .ics .vcf files would be cool
}