import Button from "@material-ui/core/Button";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import PageContainer from "../../components/PageContainer";
import PageHeadline from "../../components/PageHeadline";
import PageSection from "../../components/PageSection";
import { PageComponent } from "../../types/PageComponent";
import useViewModel from "../../utils/useViewModel";
import LayoutViewModel from "../layout/LayoutViewModel";

const WelcomePage: PageComponent = observer(props => {

    const viewModel = useViewModel(LayoutViewModel);

    useEffect(() => {

        const origin = (new URLSearchParams(props.location.search)).get("origin");

        if (origin) {
            viewModel.loginReturnPath = origin;
        }
    }, []);

    return (
        <PageContainer>
            <PageHeadline>Willkommen auf unserem Kundenportal</PageHeadline>
            <PageSection>
                {!!viewModel.userInfo ? (
                    <>
                        <p>Sie sind bereits angemeldet.</p>
                        <Button variant="outlined" size="large" onClick={viewModel.goToStartPage}>fahren Sie fort</Button>
                    </>
                ) : (
                    <>
                        <p>Um das Kundenportal nutzen zu können, müssen Sie sich mit Ihrer Kundennummer und dem von ihnen festgelegten Kennwort anmelden.</p>
                        <Button variant="outlined" size="large" onClick={viewModel.loginDialog.open}>jetzt anmelden</Button>
                    </>
                )}
            </PageSection>
        </PageContainer>
    );
});

export default WelcomePage;