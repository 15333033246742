import React from "react";
import styled from "styled-components";
import CircularProgress from "@material-ui/core/CircularProgress";

interface IProgressBoxProps {
    minHeight?: string | number;
    width?: string | number;
}

const ProgressContainerBase = styled.div<IProgressBoxProps>(props => ({
    minHeight: props.minHeight,
    width: props.width,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center"
}));

const ProgressBox: React.FC<IProgressBoxProps> = props => (
    <ProgressContainerBase {...props}>
        <CircularProgress />
    </ProgressContainerBase>
);

ProgressBox.defaultProps = {
    minHeight: "25vh"
};

export default ProgressBox;