import React, { CSSProperties, useRef, useState } from "react";
import styled from "styled-components";
import loadable from "@loadable/component";
import Paper from "@material-ui/core/Paper";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import WebOutlinedIcon from "@material-ui/icons/WebOutlined";
import HourglassEmptyOutlinedIcon from "@material-ui/icons/HourglassEmptyOutlined";
import BallotIcon from "@material-ui/icons/Ballot";
import IBrowseDirectoryInfo from "@shared/interfaces/IBrowseDirectoryInfo";
import { EditTileAction } from "../../models/EditTileAction";
import getAutoDeleteDays from "../../utils/getAutoDeleteDays";
import TileBase from "./TileBase";
import UnsafeSourceImg from "./UnsafeSourceImg";
import TileBadge from "./TileBadge";
import TitleBadgeIcon from "./TitleBadgeIcon";

const EditTileMenu = loadable(() => import("./EditTileMenu"));

const TilePaper = styled(Paper)(props => ({
    backgroundColor: props.theme.palette.primary.main,
    color: props.theme.palette.primary.contrastText,
    textTransform: "uppercase",
    padding: props.theme.spacing(2),
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    overflow: "hidden"
}));

const TileBadgesRoot = styled.div(props => ({
    position: "absolute",
    top: props.theme.spacing(1.5),
    right: props.theme.spacing(1.5),
    display: "flex",
    flexDirection: "row-reverse",
    maxWidth: "80%",
    overflow: "hidden",
    zIndex: 1,
    "& > *": {
        marginLeft: props.theme.spacing(1)
    }
}));

const TileIcon = styled(UnsafeSourceImg)({
    flex: "1 1 auto",
    maxWidth: "50%",
    maxHeight: "50%",
    marginBottom: "1em",
    filter: "brightness(0) invert(1)", // black to white
    "&:last-child": {
        marginBottom: 0
    }
});

const TileText = styled.span<{ small?: boolean }>(props => ({
    overflow: "hidden",
    maxWidth: "100%",
    textOverflow: "ellipsis",
    fontSize: props.small ? "0.8em" : undefined,
    lineHeight: "1.25"
}));

interface ITileProps {
    info: IBrowseDirectoryInfo;
    notificationCount: number;
    onClick: (info: IBrowseDirectoryInfo) => void;
    onEdit: (info: IBrowseDirectoryInfo, action: EditTileAction) => void;
    canMoveLeft: boolean;
    canMoveRight: boolean;
    canSetIcon: boolean;
    canSetAutoDelete: boolean;
    canEditRestrict: boolean;
    showDirInfos?: boolean;
}

const Tile: React.FC<ITileProps> = props => {

    const tileRef = useRef<Element>();
    const [menuPosition, setShowMenuPosition] = useState<{ top: number, left: number } | null>(null);

    const closeEditMenu = () => setShowMenuPosition(null);

    const { info, showDirInfos, notificationCount } = props;

    const style: CSSProperties | undefined = info.background ? {
        backgroundImage: `url(${info.background})`
    } : undefined;

    const menuActions = new Array<EditTileAction>();

    if (props.canSetIcon) {
        menuActions.unshift(EditTileAction.icon);
    }
    if (props.canEditRestrict) {
        menuActions.unshift(EditTileAction.restrict);
    }
    if (props.canMoveLeft) {
        menuActions.unshift(EditTileAction.oneStepLeft);
    }
    if (props.canMoveRight) {
        menuActions.unshift(EditTileAction.oneStepRight);
    }
    if (props.info.type === "directory") {
        if (props.canSetAutoDelete) {
            menuActions.push(EditTileAction.autoDelete);
        }
        menuActions.push(EditTileAction.download);
    }

    const autoDeleteDays = getAutoDeleteDays(info);

    return (
        <TileBase
            innerRef={tileRef}
            onContextMenu={event => {
                event.preventDefault();
                setShowMenuPosition({
                    top: event.clientY,
                    left: event.clientX
                });
            }}
            onClick={() => props.onClick(info)}
        >
            <TilePaper elevation={2} square style={style}>
                <TileBadgesRoot>
                    {!!notificationCount && (
                        <TileBadge>{notificationCount}</TileBadge>
                    )}
                    {showDirInfos && info.restrict && (
                        <TitleBadgeIcon icon={LockOutlinedIcon} tooltip="Zugriffsbeschränkung" />
                    )}
                    {showDirInfos && info.type === "collect" && (
                        <TitleBadgeIcon icon={ListAltOutlinedIcon} tooltip="Sammelverzeichnis" />
                    )}
                    {showDirInfos && info.type === "embed" && (
                        <TitleBadgeIcon icon={WebOutlinedIcon} tooltip="Eingebettete App" />
                    )}
                    {showDirInfos && info.type === "forms" && (
                        <TitleBadgeIcon icon={BallotIcon} tooltip="Formular" />
                    )}
                    {showDirInfos && !!autoDeleteDays && (
                        <TitleBadgeIcon icon={HourglassEmptyOutlinedIcon} tooltip={`Löschen nach ${autoDeleteDays} Tagen`} />
                    )}
                </TileBadgesRoot>
                {info.icon && (
                    <TileIcon src={info.icon} />
                )}
                {!info.background && (
                    <TileText small={info.name.length > 30}>{info.name}</TileText>
                )}
                {menuActions.length > 0 && (
                    <EditTileMenu
                        context={info}
                        position={menuPosition}
                        actions={menuActions}
                        onClose={closeEditMenu}
                        onActionClick={props.onEdit}
                    />
                )}
            </TilePaper>
        </TileBase>
    );
}

export default Tile;