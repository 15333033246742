import IUserInfo from "@shared/interfaces/IUserInfo";
import { UserRole } from "@shared/models/UserRole";
import { UserType } from "@shared/models/UserType";

export default class RoutePath<R extends Record<string, string> | undefined = undefined> {

    readonly allowedUsers: UserType | Array<UserRole> | null;

    readonly template: string;

    constructor(template: string, allowedUsers: UserType | Array<UserRole> | null = null) {

        this.template = template;
        this.allowedUsers = allowedUsers;
    }

    get(replacements?: R): string {

        if (replacements) {

            let path = this.template;

            for (const replaceKey in replacements) {
                if (replaceKey === "0" || (replaceKey as any) === 0) {
                    path = path.replace(`*`, replacements[replaceKey.toString()]);
                } else {
                    path = path.replace(`:${replaceKey}`, replacements[replaceKey.toString()]);
                }
            }

            return path;
        }

        return this.template;
    }

    hasAccess(user: IUserInfo | null) {

        if (!this.allowedUsers || user?.type === UserType.admin) {
            return true;
        }

        if (!user) {
            return !this.allowedUsers;
        }

        if (Array.isArray(this.allowedUsers)) {
            return user.type === UserType.backOffice && this.allowedUsers.every(r => user.roles.includes(r))
        }

        if (this.allowedUsers === UserType.customer) {

            return [UserType.customer, UserType.backOffice, UserType.admin].includes(user.type)
        }

        return user.type === this.allowedUsers;
    }
}