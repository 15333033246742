import React from "react";
import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import INewsResponseItem from "@shared/interfaces/INewsResponseItem";
import replaceLeading from "@shared/utils/replaceLeading";
import SurfaceHeadline from "../SurfaceHeadline";
import { paths } from "../../routes";
import SplitStack from "../SplitStack";
import TextLink from "../TextLink";

const NewsContainerRoot = styled.section(props => ({
    flex: 0,
    border: `2px solid ${props.theme.palette.primary.main}`,
    borderRadius: 6,
    minWidth: 200,
    maxWidth: 240,
    marginRight: props.theme.spacing(2),
    padding: props.theme.spacing(2),
    maxHeight: 600,
    overflowY: "auto",
    overflowX: "hidden",
    "& > :last-child": {
        marginBottom: 0
    },
    alignSelf: "flex-start"
}));

function createBrowseLink(item: INewsResponseItem) {

    return paths.browse.get({ "0": replaceLeading(item.path, "/", "") });
}

interface INewsSidebarProps {
    news: Array<INewsResponseItem>;
    children?: never;
}

const NewsSidebar: React.FC<INewsSidebarProps> = props => (
    <NewsContainerRoot>
        <SurfaceHeadline>Wichtige Informationen</SurfaceHeadline>
        <SplitStack splitBy={<hr />}>
            {props.news.map(n => (
                <React.Fragment key={`${n.title}_${n.path}`}>
                    <TextLink to={createBrowseLink(n)} color="inherit">
                        <Typography variant="h6">{n.title}</Typography>
                    </TextLink>
                    <Typography variant="body2">{n.body}</Typography>
                </React.Fragment>
            ))}
        </SplitStack>
    </NewsContainerRoot>
);

export default NewsSidebar;