import styled from "styled-components";

const gridGaps = {
    tight: 1,
    loose: 6,
    default: 4
};

interface IPageSectionProps {
    columns?: 1 | 2 | 3 | 4;
    gap?: "tight" | "default" | "loose";
}

const PageSection = styled.section<IPageSectionProps>(props => ({
    display: props.columns === 1 ? "block" : "grid",
    gap: props.theme.spacing(gridGaps[props.gap!]),
    gridTemplateColumns: `repeat(${props.columns}, minmax(0, 1fr))`,
    "&:not(:last-child)": {
        marginBottom: props.theme.spacing(4)
    },
    [props.theme.breakpoints.down("md")]: {
        gridTemplateColumns: `repeat(${Math.min(props.columns!, 3)}, minmax(0, 1fr))`
    },
    [props.theme.breakpoints.down("sm")]: {
        gridTemplateColumns: `repeat(${Math.min(props.columns!, 2)}, minmax(0, 1fr))`
    },
    [props.theme.breakpoints.down("xs")]: {
        gridTemplateColumns: `minmax(0, 1fr)`
    }
}));

PageSection.defaultProps = {
    columns: 1,
    gap: "default"
};

export default PageSection;