import React from "react";
import PageContainer from "../../components/PageContainer";
import Sign from "../../components/Sign";
import Link from "@material-ui/core/Link";
import CancelIcon from "@material-ui/icons/Cancel";
import TextLink from "../../components/TextLink";

interface ITitleAndBody {
    title: string;
    body: React.ReactNode;
}

function getErrorCode(location: Location) {

    const code = (new URLSearchParams(location.search)).get("code") || "0";
    return isNaN(+code) ? 0 : +code;
}

function getTitleAndBodyForErrorCode(code: number): ITitleAndBody {

    if (code === 400) {
        return {
            title: "Anfrageparameter unzureichend",
            body: (
                <>
                    <p>Diese Seite erfordert konnte nicht geöffnet werden, da die entsprechenden Parameter nicht mitgesendet wurden.</p>
                    <p>Sollte dieser Fehler wiederholt auftreten, melden Sie sich bei uns, damit wir diesen beseitigen können.</p>
                </>
            )
        };
    }

    if (code === 403) {
        return {
            title: "Authorisirung erforderlich",
            body: (
                <>
                    <p>Sie sind nicht authorisiert diese Seite zu sehen.</p>
                    <p>Bitte melden Sie sich mit einem Account an, dem die entsprechenden Berechtigungen zugewiesen sind.</p>
                </>
            )
        };
    }

    if (code === 404) {
        return {
            title: "Die angefragt Adresse konnte nicht gefunden werden",
            body: <p>Zur Startseite kehren Sie mit <TextLink to="/">diesem Link</TextLink> zurück</p>
        };
    }

    const reload = () => window.location.reload();

    return {
        title: "Es ist ein Fehler aufgetreten",
        body: (
            <>
                <p>Leider ist ein unerwarteter Fehler aufgetreten. Versuchen Sie diese Seite <Link onClick={reload}>neu zu laden</Link>.</p>
                <p>Sollte der Fehler dann immer noch auftreten, melden Sie sich bei uns, damit wir den Fehler beseitigen können.</p>
            </>
        )
    };
}

const ErrorPage: React.FC = props => {

    const code = getErrorCode(props && (props as any).location || window.location);
    const { title, body } = getTitleAndBodyForErrorCode(code);

    return (
        <PageContainer>
            <Sign text={ title} icon={CancelIcon}>
                {body}
            </Sign>
        </PageContainer>
    );
}

export default ErrorPage;