import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import SettingsIcon from "@material-ui/icons/Settings";
import MenuIcon from "@material-ui/icons/Menu";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import useViewModel from "../../utils/useViewModel";
import logo from "../../images/logo.svg";
import useMuiBreakpoint from "../../utils/useMuiBreakpoint";
import ResponsiveSearch from "../../components/search/ResponsiveSearch";
import MainNavigation from "../browse/MainNavigation";
import Slot from "../../components/Slot";
import LayoutViewModel from "./LayoutViewModel";
import AccountKnob from "./AccountKnob";
import FileNotificationsKnob from "./FileNotificationsKnob";

const MainMenuContainer = styled(Paper)({
    position: "static"
});

const Banderole = styled.div(props => ({
    backgroundColor: props.theme.palette.primary.main,
    color: props.theme.palette.primary.contrastText,
    fontSize: 20,
    lineHeight: "40px",
    paddingLeft: props.theme.spacing(3),
    paddingRight: props.theme.spacing(3),
    display: "flex",
    justifyContent: "space-between"
}));

const Logo = styled.img<{ stretch?: boolean }>(props => ({
    height: 28,
    marginRight: props.stretch ? "auto" : props.theme.spacing(3),
    maxWidth: "30vw", // responsive hack
    cursor: "pointer"
}));

const Filler = styled.div({
    flexGrow: 1
});

const MainMenu: React.FC = observer(() => {

    const viewModel = useViewModel(LayoutViewModel);
    
    const isSmallDevice = useMuiBreakpoint("down", "xs");

    const [showNews, setShowNews] = useState(false);

    return (
        <>
            <MainMenuContainer elevation={2}>
                <Toolbar>
                    <Logo src={logo} onClick={viewModel.startPageLinkClick} stretch={isSmallDevice} />
                    {!!viewModel.userInfo && (
                        <ResponsiveSearch />
                    )}
                    {!isSmallDevice && <Filler />}
                    <AccountKnob
                        user={viewModel.userInfo}
                        onLoginClick={viewModel.loginDialog.open}
                        onLogoutClick={viewModel.logout}
                        onAdminSettingsClick={viewModel.backOfficeSideMenu.open}
                        onChangePasswordClick={viewModel.changePasswordDialog.open}
                    />
                    <FileNotificationsKnob
                        announcements={viewModel.activeAnnouncements}
                        fileNotifications={viewModel.fileNotifications}
                        onDirectoryClick={viewModel.browseTo}
                        onAnnouncementClick={viewModel.showAnnouncement}
                        onDismissFileNotificationClick={viewModel.readFileNotification}
                        onDismissFileNotificationsDirClick={viewModel.readFileNotificationsInDir}
                        onDismissAllClick={viewModel.canDismissAllNotifications ? viewModel.readAllFileNotifications : undefined}
                        atEndEdge={!viewModel.canViewBackOfficeMenu && !showNews}
                    />
                    <Slot id="news" onHasChildNodes={setShowNews} />
                    {viewModel.canViewBackOfficeMenu && (
                        <Tooltip title="BackOffice-Menu">
                            <IconButton
                                color={viewModel.backOfficeSideMenu.isOpen ? "inherit" : "default"}
                                onClick={viewModel.backOfficeSideMenu.toggle}
                                edge="end"
                            >
                                <SettingsIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </Toolbar>
                <Banderole>
                    <a onClick={viewModel.startPageLinkClick}>Kundenportal</a>
                    {isSmallDevice && (
                        <IconButton size="small" color="inherit" edge="end" onClick={viewModel.toggleMainMenu}>
                            {viewModel.isMainMenuOpen ? <ExpandLessIcon /> : <MenuIcon />}
                        </IconButton>
                    )}
                </Banderole>
                {!!viewModel.userInfo && (
                    <MainNavigation
                        rooDirectories={viewModel.rooDirectories}
                        currentPath={viewModel.currentBrowsePath}
                        onClick={viewModel.onMainMenuItemClick}
                        menuOpen={viewModel.isMainMenuOpen}
                    />
                )}
            </MainMenuContainer>
        </>
    );
});

export default MainMenu;