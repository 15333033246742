import styled from "styled-components";

export const TilesGrid = styled.div(props => ({
    display: "grid",
    gap: props.theme.spacing(0.5),
    gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
    "& > *::before": { // make square
        content: "''",
        paddingBottom: "100%",
        display: "block"
    },
    "@media (min-width: 320px) and (max-width: 740px)": {
        gridTemplateColumns: "minmax(50%, 1fr) minmax(50%, 1fr)"
    },
    "@media (max-width: 320px)": {
        gridTemplateColumns: "1fr"
    }
}));

export default TilesGrid;