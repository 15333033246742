import React, { useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import loadable from "@loadable/component";
import IconButton from "@material-ui/core/IconButton";
import FolderIcon from "@material-ui/icons/Folder";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Popover from "@material-ui/core/Popover";
import ISearchResult from "@shared/interfaces/ISearchResult";
import useMuiBreakpoint from "../../utils/useMuiBreakpoint";
import FullscreenDialog from "../FullscreenDialog";
import AppBarTextField from "../forms/AppBarTextField";
import SurfaceHeadline from "../SurfaceHeadline";
import FileCategoryIcon from "../FileCategoryIcon";
import useViewModel from "../../utils/useViewModel";
import SearchViewModel from "./SearchViewModel";

function getResultIcon(type: string, viewModel: SearchViewModel): React.ReactElement {

    if (type === "dir") {
        return <FolderIcon />;
    }

    const category = viewModel.getFileCategory(type);

    return <FileCategoryIcon category={category} />
}

const AppBarTextFieldInDialog = styled(AppBarTextField)({
    marginRight: "1rem",
    marginLeft: "1rem",
    flexGrow: 1
});

const ResponsiveSearchAppBarTextField = styled(AppBarTextField)(props => ({
    marginRight: "auto",
    border: `2px solid ${props.theme.palette.primary.main}`
}));

const ResultsListPopover = styled(Popover)({
    minWidth: "40vw"
});

const NoResultsText = styled(SurfaceHeadline)(props => ({
    margin: props.theme.spacing(2)
}));

const SearchResultsList = loadable(() => import("./SearchResultsList"));

const ResponsiveSearch: React.FC = observer(() => {

    const viewModel = useViewModel(SearchViewModel);

    const isSmallDevice = useMuiBreakpoint("down", "xs");

    const [isDialogOpen, setDialogOpen] = useState(false);

    const searchFieldRef = useRef<Element>();

    const onFieldChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        viewModel.searchHandler.search(e.currentTarget.value || "");
    };

    const onFieldBlur = () => setTimeout(
        () => viewModel.searchHandler.clear(),
        200
    );

    const onResultSelect = (result: ISearchResult) => {
        setDialogOpen(false);
        viewModel.onResultSelect(result);
    }

    let { results, lastSearch } = viewModel.searchHandler;
    results = results.slice(0, 8);

    if (isSmallDevice) {

        const fullScreenHeader = (
            <Toolbar>
                <Typography variant="h6" noWrap>
                    Suche
                </Typography>
                <AppBarTextFieldInDialog
                    endIcon={<SearchIcon />}
                    value={lastSearch}
                    onChange={onFieldChange}
                    autoFocus={true}
                />
                <IconButton color="inherit" onClick={() => setDialogOpen(false)}>
                    <CloseIcon />
                </IconButton>
            </Toolbar>
        );

        return (
            <>
                <IconButton color="inherit" onClick={() => setDialogOpen(true)}>
                    <SearchIcon />
                </IconButton>
                <FullscreenDialog header={fullScreenHeader} open={isDialogOpen} onClose={() => setDialogOpen(false)}>
                    {results.length > 0 ? (
                        <SearchResultsList
                            results={results}
                            onResultClick={onResultSelect}
                            getResultIcon={result => getResultIcon(result.type, viewModel)}
                        />
                    ) : (
                        <NoResultsText>Keine Ergebnisse</NoResultsText>
                    )}
                </FullscreenDialog>
            </>
        );
    }

    return (
        <>
            <ResponsiveSearchAppBarTextField
                endIcon={<SearchIcon />}
                value={lastSearch}
                onChange={onFieldChange}
                onBlur={onFieldBlur}
                elementRef={searchFieldRef}
            />
            <ResultsListPopover
                open={!!lastSearch && results.length > 0}
                anchorEl={searchFieldRef.current}
                onClose={() => onFieldBlur()}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
                disableAutoFocus={true}
                disableEnforceFocus={true}
            >
                {results.length > 0 && (
                    <SearchResultsList
                        results={results}
                        onResultClick={onResultSelect}
                        getResultIcon={result => getResultIcon(result.type, viewModel)}
                    />
                )}
            </ResultsListPopover>
        </>
    );
});

export default ResponsiveSearch;