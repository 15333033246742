import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import { Alert, Color } from "@material-ui/lab";
import { INotificationAction, NotificationType } from "../../services/NotificationService";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import SnackbarContent from "@material-ui/core/SnackbarContent";

interface INotificationItemProps {
    children: React.ReactElement | string;
    action: INotificationAction | null,
    closable: boolean;
    type: NotificationType;
    onClose: () => void;
}

const NotificationItem: React.FC<INotificationItemProps> = React.forwardRef(({ children, action, closable, type, onClose }, ref) => {

    const renderAlert = type !== NotificationType.normal;

    const actionButton = action && (
        <Button color={renderAlert ? undefined : "primary"} size="small" onClick={action.handle}>
            {action.label}
        </Button>
    );

    const actionElements = (
        <>
            {actionButton}
            {closable && (
                <IconButton size="small" aria-label="close" color="inherit" onClick={onClose}>
                    <CloseIcon fontSize="small" />
                </IconButton>
            )}
        </>
    );

    if (renderAlert) {

        return (
            <Alert
                ref={ref}
                onClose={(closable && !action) ? onClose : undefined}
                severity={type as Color}
                action={(closable && action) ? actionElements : actionButton}
            >
                {children}
            </Alert>
        );
    }

    return (
        <SnackbarContent
            ref={ref}
            action={actionElements}
            message={children}
        />
    );
});

export default NotificationItem;