import React, { useEffect } from "react";

const useHasChildNodes = (
    ref: React.MutableRefObject<Element | null>,
    callback: (hasChildNodes: boolean) => void,
    initially?: boolean
) => useEffect(() => {

    if (ref.current) {

        if (initially) {
            callback(ref.current.hasChildNodes());
        }

        const observerCallback = (mutations: Array<MutationRecord>) => {
            if (mutations.some(m => m.type === "childList")) {
                callback(ref.current ? ref.current.hasChildNodes() : false);
            }
        };

        const observer = new MutationObserver(observerCallback);
        observer.observe(ref.current, { childList: true });

        return () => observer.disconnect();
    }

}, [callback]);

export default useHasChildNodes;