import dateJsonReviver from "../utils/dateJsonReviver";

export default class LocalObjectStorage<T> {

    readonly name: string;

    private readonly defaultValue: T;

    private readonly checkFn?: (value: unknown) => value is T;

    private get storage() {

        return window.localStorage;
    }

    constructor(name: string, defaultValue: T, checkFn?: (value: unknown) => value is T) {
        this.name = name;
        this.defaultValue = defaultValue;
        this.checkFn = checkFn;
    }

    read(): T {

        try {
            const stringValue = this.storage.getItem(this.name);
            if (stringValue) {
                const parsedObj = JSON.parse(stringValue, dateJsonReviver);

                if (!this.checkFn || this.checkFn(parsedObj)) {

                    return parsedObj as T;

                } else {
                    this.storage.removeItem(this.name);
                    console.error(`Storage with key "${this.name}" cleared due to corrupted value`);
                }                
            }
        } catch (error) {
            console.error(`Could not parse value from storage with key "${this.name}"`);
        }

        return this.defaultValue;
    }

    write(value: T): boolean {

        try {
            const stringValue = JSON.stringify(value);
            this.storage.setItem(this.name, stringValue);

            return true;

        } catch (error) {
            console.error(`Could write value into storage with key "${this.name}"`);

            return false;
        }
    }

    clear(): boolean {

        try {
            this.storage.removeItem(this.name);
            return true;

        } catch (error) {
            return false;
        }
    }
}