import React from "react";
import Container from "typedi";
import type { RouteConfig, RouteConfigComponentProps } from "react-router-config";
import loadable from "@loadable/component";
import { withErrorBoundary } from "react-error-boundary";
import { Redirect } from "react-router-dom";
import { UserType } from "@shared/models/UserType";
import { UserRole } from "@shared/models/UserRole";
import RoutePath from "./utils/RoutePath";
import UserService from "./services/UserService";
import NotificationService, { NotificationType } from "./services/NotificationService";
import Layout from "./pages/layout/Layout";
import NotFound from "./pages/NotFound";
import WelcomePage from "./pages/welcome/WelcomePage";
import ErrorPage from "./pages/error/ErrorPage";
import Browse from "./pages/browse/Browse";

export const paths = {
    startPage: new RoutePath("/", UserType.customer),
    notFound: new RoutePath("/not-found"),
    error: new RoutePath("/error"),
    welcomePage: new RoutePath("/welcome"),
    customerAccounts: new RoutePath("/backoffice/customer-accounts", [UserRole.manageUsers]),
    backOfficeAccounts: new RoutePath("/backoffice/backoffice-accounts", [UserRole.manageUsers]),
    manageNotifications: new RoutePath("/backoffice/manage-notifications", UserType.backOffice),
    manageAttributes: new RoutePath("/backoffice/manage-attributes", [UserRole.manageUsers]),
    manageNews: new RoutePath("/backoffice/manage-news", [UserRole.manageAnnouncements]),
    manageAnnouncements: new RoutePath("/backoffice/manage-announcements", [UserRole.manageAnnouncements]),
    confirmAccount: new RoutePath<{ username: string, token: string }>("/account/confirm/:username/:token"),
    browse: new RoutePath<{ "0": string }>("/browse/*"),
    embed: new RoutePath<{ "0": string }>("/embed/*"),
    collect: new RoutePath<{ "0": string }>("/collect/*"),
    forms: new RoutePath<{ "0": string }>("/forms/*"),
    docs: new RoutePath("/docs"),
    setupNetworkDrive: new RoutePath("/setup-network-drive", UserType.backOffice),
    setExternalReference: new RoutePath("/set-external-reference", [UserRole.setExternalReference]),
    imprint: new RoutePath("/imprint")
};

function renderRestrictedRoute<P extends RouteConfigComponentProps>(Component: React.ComponentType<P>, routePath: RoutePath) {

    return (props: P) => {
      
        const userService = Container.get(UserService);

        const canView = routePath.hasAccess(userService.userInfo);

        return canView ?
            (<Component {...props} />) :
            (<Redirect to={paths.welcomePage.get() + `?origin=${props.location.pathname}`} />);
    };
}

function withErrorHandler<P>(Component: React.ComponentType<P>): React.ComponentType<P> {

    const notificationService = Container.get(NotificationService);

    return withErrorBoundary(Component, {
        onError: () => notificationService.show("Es ist ein Fehler aufgetreten", NotificationType.error, true, null, 10000),
        FallbackComponent: ErrorPage
    });
}

function routeConfigFromRestrictedPath(
    routePath: RoutePath<any>,
    Component: React.ComponentType<any>,
    handleErrors = false,
    options?: { exact?: boolean, strict?: boolean, routes?: Array<RouteConfig> }
): RouteConfig {

    return {
        ...options,
        path: routePath.template,
        render: renderRestrictedRoute(handleErrors ? withErrorHandler(Component) : Component, routePath)
    };
}

const routes: Array<RouteConfig> = [
    {
        component: Layout,
        routes: [
            routeConfigFromRestrictedPath(paths.startPage, Browse, true, { exact: true }),
            routeConfigFromRestrictedPath(paths.welcomePage, WelcomePage, true),
            routeConfigFromRestrictedPath(paths.customerAccounts, loadable(() => import("./pages/backOffice/CustomerAccounts")), true),
            routeConfigFromRestrictedPath(paths.backOfficeAccounts, loadable(() => import("./pages/backOffice/BackOfficeAccounts")), true),
            routeConfigFromRestrictedPath(paths.manageNotifications, loadable(() => import("./pages/backOffice/ManageNotifications")), true),
            routeConfigFromRestrictedPath(paths.manageAttributes, loadable(() => import("./pages/backOffice/ManageAttributes")), true),
            routeConfigFromRestrictedPath(paths.setExternalReference, loadable(() => import("./pages/backOffice/SetExternalReference")), true),
            routeConfigFromRestrictedPath(paths.manageNews, loadable(() => import("./pages/backOffice/ManageNews")), true),
            routeConfigFromRestrictedPath(paths.manageAnnouncements, loadable(() => import("./pages/backOffice/ManageAnnouncements")), true),
            routeConfigFromRestrictedPath(paths.confirmAccount, loadable(() => import("./pages/account/Confirm")), true),
            routeConfigFromRestrictedPath(paths.browse, Browse, true),
            routeConfigFromRestrictedPath(paths.embed, loadable(() => import("./pages/browse/Embed")), true),
            routeConfigFromRestrictedPath(paths.collect, loadable(() => import("./pages/browse/Collect")), true),
            routeConfigFromRestrictedPath(paths.forms, loadable(() => import("./pages/browse/Forms")), true),
            routeConfigFromRestrictedPath(paths.docs, () => <Redirect to="/browse/docs/" />, false),
            routeConfigFromRestrictedPath(paths.setupNetworkDrive, loadable(() => import("./pages/backOffice/SetupNetworkDrive")), true),
            {
                path: paths.imprint.template,
                component: loadable(() => import("./pages/imprint/Imprint"))
            },
            {
                path: paths.notFound.template,
                component: NotFound
            },
            {
                path: paths.error.template,
                component: ErrorPage
            },
            {
                path: "*",
                component: NotFound
            }
        ]
    }
];

export default routes;