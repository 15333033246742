import React from "react"
import styled from "styled-components";
import grey from "@material-ui/core/colors/grey";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const SignContainer = styled.div<{ marginTop?: boolean }>(props => ({
    width: "20vw",
    minWidth: 350,
    textAlign: "center",
    marginTop: props.marginTop ? "10vh" : undefined,
    marginLeft: "auto",
    marginRight: "auto"
}));

const SignText = styled(Typography)(props => ({
    marginBottom: props.theme.spacing(3)
}));

const useStyles = makeStyles((theme) => ({
    signIcon: {
        color: grey[400],
        fontSize: 100
    }
}));

interface ISignProps {
    icon: React.ComponentType<{ className: string }>;
    text: string;
    marginTop?: boolean;
}

const Sign: React.FC<ISignProps> = props => {

    const { signIcon } = useStyles();

    return (
        <SignContainer marginTop={props.marginTop}>
            {React.createElement(props.icon, { className: signIcon })}
            <SignText variant="h4">
                {props.text}
            </SignText>
            {props.children}
        </SignContainer>
    );
}

export default Sign;