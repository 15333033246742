const slash = "/";

export default function sanitizePath(path: string) {

    if (path === slash || !path) {
        return slash;
    }

    path = path.replace(/\s?\/\s?/g, "/").trim();

    if (path.indexOf(slash) !== 0) {
        path = slash + path;
    }

    if (path.endsWith(slash)) {
        path = path.substring(0, path.length - 1);
    }

    return path;
}