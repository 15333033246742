import React, { useRef } from "react";
import styled from "styled-components";

const UploadForm = styled.form({
    position: "relative",
    "& > input[type='file']": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        opacity: 0,
        cursor: "pointer"
    }
});

interface IFileUploadUploadProps {
    onUpload: (formData: FormData) => void;
    acceptMimeTypes?: string;
    className?: string;
}

const FileUpload: React.FC<IFileUploadUploadProps> = props => {

    const formRef = useRef<HTMLFormElement>(null);

    const onUploadIconFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {

        event.preventDefault();
        event.stopPropagation();

        props.onUpload(new FormData(event.currentTarget));

        return false;
    };

    const onFileInputChange = () => {

        const form = formRef.current;
        if (form) {
            if ("requestSubmit" in form) {
                form.requestSubmit();
            } else {
                form.dispatchEvent(new Event("submit", { cancelable: true }));
            }
        }
    };

    return (
        <UploadForm
            ref={formRef}
            onSubmit={onUploadIconFormSubmit}
            method="post"
            encType="multipart/form-data"
            className={props.className}
        >
            {props.children}
            <input type="file" name="file" accept={props.acceptMimeTypes} onChange={onFileInputChange} />
        </UploadForm>
    );
}

export default FileUpload;