import { makeAutoObservable } from "mobx";
import { Inject, Service } from "typedi";
import { AuthStatus } from "@shared/requests/AuthStatus";
import { IAccountTokenPayload } from "@shared/interfaces/IAccountTokenPayload";
import { AuthLogin } from "@shared/requests/AuthLogin";
import { AuthLogout } from "@shared/requests/AuthLogout";
import { parseFromArray, UserType } from "@shared/models/UserType";
import { parseAllUserRoles, UserRole } from "@shared/models/UserRole";
import IUserInfo from "@shared/interfaces/IUserInfo";
import { authStatusToken, authLoginToken, authLogoutToken } from "../requests";
import NotificationService, { NotificationType } from "./NotificationService";

export interface ILoginResponse {
    success: boolean;
    error?: string;
}

@Service()
export default class UserService {

    private _userInfo: IUserInfo | null = null;

    private _isInitialized = false;

    private refreshStatusTimer?: number;

    private _lastRefresh = new Date();

    private readonly notificationService: NotificationService;

    private readonly authStatus: AuthStatus;
    private readonly authLogin: AuthLogin;
    private readonly authLogout: AuthLogout;

    get isLoggedIn() {
        return !!this._userInfo;
    }

    get isInitialized() {
        return this._isInitialized;
    }

    get lastRefresh() {
        return this._lastRefresh;
    }

    get userInfo(): IUserInfo | null {
        return this._userInfo;
    }

    constructor(
        @Inject() notificationService: NotificationService,
        @Inject(authStatusToken) authStatus: AuthStatus,
        @Inject(authLoginToken) authLogin: AuthLogin,
        @Inject(authLogoutToken) authLogout: AuthLogout
    ) {
        this.notificationService = notificationService;
        this.authStatus = authStatus;
        this.authLogin = authLogin;
        this.authLogout = authLogout;

        makeAutoObservable(this, undefined, { deep: false });
    }

    async init() {

        await this.refreshStatus();

        this._isInitialized = true;

        this.setupRefreshInterval();
    }

    async login(username: string, password: string, externalReference?: string): Promise<ILoginResponse> {

        const response = await this.authLogin.send({}, {
            username,
            password,
            externalReference
        }, {});

        if (!response.success) {
            
            return {
                success: false,
                error: response.error
            };
        }

        const userInfo: IUserInfo | null = response.success ? this.userInfoFromAccountPayload(response.result) : null

        this._userInfo = userInfo;
        this._lastRefresh = new Date();

        this.setupRefreshInterval();

        return { success: true };
    }

    async logout(): Promise<void> {

        await this.authLogout.send({}, null, {});

        this._userInfo = null;
        this._lastRefresh = new Date();

        window.clearInterval(this.refreshStatusTimer);
    }

    async refreshStatus() {

        const response = await this.authStatus.send({}, null, {});

        if (!response.success && response.status >= 500) {

            this.notificationService.show(
                "Keine Verbindung zum Server! Wir bitten um Verzeihung",
                NotificationType.error,
                false,
                null,
                10000
            );
        }

        const user = response.success &&
            response.result.authenticated &&
            response.result.payload &&
            this.userInfoFromAccountPayload(response.result.payload) ||
            null;

        if (user) {
            this.setupRefreshInterval();
        }

        this._userInfo = user;
        this._lastRefresh = new Date();
    }

    getIsBackOfficeUser(...withRoles: Array<UserRole>) {

        if (!this._userInfo) {
            return false;
        }

        if (this._userInfo.type === UserType.admin) {
            return true;
        }

        if (this._userInfo.type !== UserType.backOffice) {
            return false;
        }

        if (withRoles.length) {
            return withRoles.every(r => this._userInfo!.roles.includes(r));
        }

        return true;
    }

    private setupRefreshInterval() {

        window.clearInterval(this.refreshStatusTimer);
        this.refreshStatusTimer = window.setInterval(() => this.refreshStatus(), 1000 * 120);
    }

    private userInfoFromAccountPayload(payload: IAccountTokenPayload): IUserInfo {

        const domain = payload.roles.find(r => r.startsWith("domain."))?.substring(7) || null;

        return {
            username: payload.username,
            displayName: payload.displayName,
            externalReference: payload.externalReference,
            type: parseFromArray(payload.roles) || UserType.customer,
            roles: parseAllUserRoles(payload.roles),
            email: payload.email || null,
            domain
        };
    }
}