import { HttpMethod, RequestDefinition, ResponseType } from "@shared/requests/RequestDefinition";
import replaceLeading from "@shared/utils/replaceLeading";
import isDevEnv from "../utils/isDevEnv";
import dateJsonReviver from "./dateJsonReviver";

const pathParamRegex = /:[\d\w-]+\??/;

export function getApiUrl(path: string, params: Record<string, string> | null, search?: Record<string, string>) {

    if (params) {        
        for (const replaceKey in params) {
            path = path.replace(pathParamRegex, params[replaceKey]);
        }
    }

    path = path.replace(pathParamRegex, "");

    const trailing = (search && Object.keys(search).length > 0) ?
        ("?" + new URLSearchParams(search).toString()) :
        "";

    return "/api/" + replaceLeading(path, "/", "") + trailing;
}

export async function extractResponse<TResult>(fetchResponse: Response): Promise<ResponseType<TResult>> {

    let jsonData: any = null;
    try {
        jsonData = JSON.parse(await fetchResponse.text(), dateJsonReviver);
    } catch (error) {
        // noop
    }

    if (!fetchResponse.ok || (jsonData && jsonData.success === false && jsonData.error)) {

        return {
            success: false,
            status: fetchResponse.status,
            error: jsonData?.error || fetchResponse.statusText
        };
    }

    return {
        success: true, 
        result: jsonData as TResult
    };
}

export async function sendApiRequest<R>(url: string, method: HttpMethod, postData: any, abortSignal?: AbortSignal): Promise<ResponseType<R>> {

    try {
        const fetchResponse = await fetch(url, {
            method,
            mode: "same-origin",
            cache: isDevEnv ? "no-cache" : undefined,
            credentials: "include",
            headers: {
                "Content-Type": "application/json"
                // "Content-Type": "application/x-www-form-urlencoded",
            },
            body: postData ? JSON.stringify(postData) : undefined,
            signal: abortSignal
        });

        return extractResponse<R>(fetchResponse);

    } catch (error) {

        return {
            success: false,
            error: ("message" in error) && error.message || "Client error",
            status: 0
        };
    }
}

export default function createApiRequest<D extends RequestDefinition<any, any, any, any, any, any>>(
    method: D["method"],
    path: D["path"]
): D {

    let abortController: AbortController | null = null;
    const abort = () => abortController?.abort();

    const send: D["send"] = async (params, postData, search) => {
        abortController = new AbortController();
        return sendApiRequest(getApiUrl(path, params, search), method, postData, abortController.signal)
    };

    return {
        path, method, send, abort
    } as D;
}
