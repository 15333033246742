import React from "react";
import styled from "styled-components";
import Collapse from "@material-ui/core/Collapse";
import IBrowseDirectoryInfo from "@shared/interfaces/IBrowseDirectoryInfo";
import { darkGrey, textWhite } from "../../theme";
import NavigationListItem from "./NavigationListItem";

const MainNavigationDropDownContainer = styled.div(props => ({
    borderTop: `${props.theme.spacing(0.5)}px solid ${props.theme.palette.background.paper}`,
    padding: props.theme.spacing(1, 2),
    background: darkGrey,
    color: textWhite
}));

interface IMainNavigationDropDownProps {
    activePath: string | null;
    items: Array<[text: string, dir: IBrowseDirectoryInfo]>;
    onClick: (dir: IBrowseDirectoryInfo) => void;
    open: boolean;
    children?: never;
}

const MainNavigationDropDown: React.FC<IMainNavigationDropDownProps> = props => {

    return (
        <Collapse in={props.open}>
            <MainNavigationDropDownContainer>
                {props.items.map(([text, dir]) => (
                    <NavigationListItem
                        key={text}
                        active={dir.path === props.activePath}
                        onClick={() => props.onClick(dir)}
                        display="block"
                        doublePitch
                    >
                        {text}
                    </NavigationListItem>
                ))}
            </MainNavigationDropDownContainer>
        </Collapse>
    );
}

export default MainNavigationDropDown;