import React from "react";
import styled from "styled-components";
import IBrowseDirectoryInfo from "@shared/interfaces/IBrowseDirectoryInfo";
import UnsafeSourceImg from "../../components/browse/UnsafeSourceImg";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";

const BreadcrumbIcon = styled(UnsafeSourceImg)<{ "data-grey": boolean }>(props => ({
    display: "inline-block",
    verticalAlign: "bottom",
    marginRight: "0.2em",
    height: "1.5em",
    maxWidth: "1.5em",
    filter: "brightness(0)" + props["data-grey"] ? " invert(0.5)" : ""
}));

interface IBreadcrumbMenuProps {
    current: IBrowseDirectoryInfo;
    parents: Array<IBrowseDirectoryInfo>;
    onItemClick: (dir: IBrowseDirectoryInfo) => void;
    hide?: boolean;
    children?: never;
}

const BreadcrumbMenu: React.FC<IBreadcrumbMenuProps> = props => {

    return (
        <Breadcrumbs aria-label="breadcrumb" style={props.hide ? { visibility: "hidden" } : undefined}>
            {props.parents.map(parent => (
                <Link key={parent.path} color="inherit" onClick={() => props.onItemClick(parent)}>
                    {parent.icon && <BreadcrumbIcon src={parent.icon} data-grey={true} />}
                    {parent.name}
                </Link>
            ))}
            <Typography color="textPrimary">
                {props.current.icon && <BreadcrumbIcon src={props.current.icon} data-grey={false} />}
                {props.current.name}
            </Typography>
        </Breadcrumbs>
    );
}

export default BreadcrumbMenu;