import replaceLeading from "./replaceLeading";
import replaceTrailing from "./replaceTrailing";

export default function joinBrowsePath(...segments: Array<string>) {

    return "/" +
        segments
            .filter(s => !!s && s !== "/")
            .map(s => replaceTrailing(replaceLeading(s, "/", ""), "/", ""))
            .join("/");
}