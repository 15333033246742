export enum UserType {
    admin = "type.admin",
    customer = "type.customer",
    backOffice = "type.backOffice",
}

export function parseUserType(from: string) {

    const userTypeValues = [
        UserType.admin,
        UserType.backOffice,
        UserType.customer
    ]

    if (userTypeValues.includes(from as UserType)) {
        return from as UserType;
    }

    return null;
}

export function parseFromArray(array: Array<string>) {

    return array.reduce<UserType | null>((result, current) => result || parseUserType(current), null);    
}