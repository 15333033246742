import { makeAutoObservable } from "mobx";
import type IClosable from "./IClosable";

export default class OpenCloseState implements IClosable {

    private otherStatesToClose = new Array<IClosable>();

    private _isOpen: boolean;

    get isOpen() {
        return this._isOpen;
    }

    constructor(open = false) {
        this._isOpen = open;

        makeAutoObservable(this, undefined, { autoBind: true });
    }

    open() {
        this.otherStatesToClose.forEach(s => s.close());
        this._isOpen = true;
    }

    close() {
        this._isOpen = false;
    }

    toggle() {
        this._isOpen = !this._isOpen;
    }

    closesOther(otherState: IClosable) {

        this.otherStatesToClose.push(otherState);
    }
}