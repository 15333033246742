import styled from "styled-components";

interface IFieldsGroupProps {
    maxWidth?: number;
    marginBottom?: boolean;
    maxRows?: number;
    minWidth?: number;
}

const FieldsGroup = styled.div<IFieldsGroupProps>(props => ({
    display: "grid",
    gap: props.theme.spacing(2, 4),
    gridAutoColumns: "1fr",
    ...(props.maxRows ? {
        gridTemplateRows: `repeat(${props.maxRows}, minmax(50px, max-content))`,
        gridAutoFlow: "column",
    } : {
        gridTemplateRows: "minmax(50px, max-content)",
    }),
    minWidth: props.minWidth,
    maxWidth: props.maxWidth
}));

FieldsGroup.defaultProps = {
    maxWidth: 420,
    minWidth: 320,
    marginBottom: true
};

export default FieldsGroup;